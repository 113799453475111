/** @jsxImportSource @emotion/react */
import React, { ReactElement } from 'react';
import { Dialog } from '@mui/material';

import { useTelegram } from '../../../../hooks/useTelegram';

import styles from './styles';

interface DeleteInfoModalProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
}

export function DeleteInfoModal({ setOpen, open }: DeleteInfoModalProps): ReactElement {
  const { colorScheme } = useTelegram();
  const onCloseHandler = () => {
    setOpen(false);
  };
  return (
    <Dialog onClose={() => setOpen(false)} open={open} css={styles.dialogStyles({ colorScheme })}>
      <div css={styles.modalWrapperStyles}>
        <div>
          <p css={styles.modalInfoTextStyles({ colorScheme })}>Your booking has been deleted</p>
        </div>
        <div css={styles.btnWrapperStyles}>
          <button type="button" css={styles.okBtnStyles} onClick={onCloseHandler}>
            OK
          </button>
        </div>
      </div>
    </Dialog>
  );
}
