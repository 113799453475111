/** @jsxImportSource @emotion/react */
import React, { ReactElement } from 'react';
import { Dialog, IconButton } from '@mui/material';
import CloseIcon from '../../../../assets/closeIcon';
import { useTelegram } from '../../../../hooks/useTelegram';

import styles from './styles';

interface ConfirmModalProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  spaceName: string;
}

export function ConfirmModal({
  setOpen,
  open,
  onCancel,
  onConfirm,
  spaceName,
}: ConfirmModalProps): ReactElement {
  const { colorScheme } = useTelegram();

  return (
    <Dialog onClose={() => setOpen(false)} open={open} css={styles.dialogStyles({ colorScheme })}>
      <div css={styles.wrapperStyles}>
        <div css={styles.headerWrapperStyles}>
          <IconButton css={styles.closeBtnStyles({ colorScheme })} onClick={onCancel}>
            <CloseIcon />
          </IconButton>
        </div>
        <div>
          <p css={styles.modalInfoTextStyles({ colorScheme })}>
            Your desk {spaceName} is unavailable for booking on the selected days. You can choose a
            new desk or make a new booking (Menu &gt; Book a desk)
          </p>
        </div>
        <div css={styles.btnWrapperStyles}>
          <button css={styles.btnStyles} type="button" onClick={onConfirm}>
            OK
          </button>
        </div>
      </div>
    </Dialog>
  );
}
