import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface BookedSpace {
  id: string;
  name: string;
  startDate: string;
  endDate: string | null;
  userSpaceId: string;
  isRepeatAlways: boolean;
  spaceUser: {
    id: string;
    name: string;
    surname: string;
    telegramUserName: string;
    telegramUserId: number;
  };
}

export interface EditedSpace {
  id: string;
  name: string;
  startDate: string;
  endDate?: string | null;
  isRepeatAlways: boolean;
}

interface OfficeSpaceState {
  chosenSpace: string;
  bookedSpaces: BookedSpace[];
  editedUserSpace: EditedSpace | null;
}

interface ChangeOfficeSpacePayload {
  newSpace: string;
}

interface SetEditedUserSpacePayload {
  newEditedUserSpace: EditedSpace | null;
}

interface SetBookedSpacesPayload {
  newBookedSpaces: BookedSpace[];
}

const initialState: OfficeSpaceState = {
  bookedSpaces: [],
  chosenSpace: '',
  editedUserSpace: null,
};

export const officeSpaceSlice = createSlice({
  name: 'officeSpaces',
  initialState,
  reducers: {
    setOfficeSpace: (state, { payload: { newSpace } }: PayloadAction<ChangeOfficeSpacePayload>) => {
      state.chosenSpace = newSpace;
    },

    setBookedSpaces: (
      state,
      { payload: { newBookedSpaces } }: PayloadAction<SetBookedSpacesPayload>,
    ) => {
      state.bookedSpaces = newBookedSpaces;
    },

    setEditedUserSpace: (
      state,
      { payload: { newEditedUserSpace } }: PayloadAction<SetEditedUserSpacePayload>,
    ) => {
      state.editedUserSpace = newEditedUserSpace;
    },
  },
});

export const {
  reducer: officeSpaceReducer,
  actions: { setOfficeSpace, setBookedSpaces, setEditedUserSpace },
} = officeSpaceSlice;
