/* eslint-disable no-restricted-syntax */
/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { Dayjs } from 'dayjs';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import { useTelegram } from '../../../../hooks/useTelegram';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { useRemoveMeetingRoomBookingMutation } from '../../../../api/rootApi';
import { EditedMeetingRoomUser } from '../../../../store/reducers/meetingRooms';
import { REPEAT_VALUE } from '../../../../constants';

import styles from './styles';

interface UserMeetingRoomInfoProps {
  id: string;
  roomId: string;
  spaceName: string;
  date: string;
  startTime: string;
  endTime: string;
  isRepeated: boolean;
  repeatValue: REPEAT_VALUE | null;
  repeatEndDate: string | null;
  isRepeatAlways: boolean;
}

function hasOnlyStartAndEndTimeChanged(
  original: EditedMeetingRoomUser,
  updated: EditedMeetingRoomUser,
): boolean {
  let hasStartTimeChanged = false;
  let hasEndTimeChanged = false;
  let hasOtherChanges = false;

  for (const prop in updated.userMeetingRoomInfo) {
    if (prop === 'startTime') {
      if (original.userMeetingRoomInfo.startTime !== updated.userMeetingRoomInfo.startTime) {
        hasStartTimeChanged = true;
      }
    } else if (prop === 'endTime') {
      if (original.userMeetingRoomInfo.endTime !== updated.userMeetingRoomInfo.endTime) {
        hasEndTimeChanged = true;
      }
    } else if (
      original.userMeetingRoomInfo[prop as keyof UserMeetingRoomInfoProps] !==
      updated.userMeetingRoomInfo[prop as keyof UserMeetingRoomInfoProps]
    ) {
      hasOtherChanges = true;
    }
  }

  return (hasStartTimeChanged || hasEndTimeChanged) && !hasOtherChanges;
}

interface EditButtonsProps {
  endTimeValue: Dayjs | null | undefined;
  startTimeValue: Dayjs | null | undefined;
  setEditModeActive: React.Dispatch<React.SetStateAction<boolean>>;
  editModeActive: boolean;
  setDeleteModalActive: React.Dispatch<React.SetStateAction<boolean>>;
  onEditHandler: () => void;
  isEditMeetingRoomBookingLoading: boolean;
  startTimeError: string;
  endTimeError: string;
  isRepeated: boolean;
  setEditRepeatBookingModalActive: React.Dispatch<React.SetStateAction<boolean>>;
  repeatValue: REPEAT_VALUE;
  repeatEndDate?: string | null;
  isRepeatAlways: boolean;
  setRemoveRepeatBookingModalActive: React.Dispatch<React.SetStateAction<boolean>>;
}

function EditButtons({
  endTimeValue,
  startTimeValue,
  setEditModeActive,
  editModeActive,
  setDeleteModalActive,
  onEditHandler,
  isEditMeetingRoomBookingLoading,
  startTimeError,
  endTimeError,
  isRepeated,
  setEditRepeatBookingModalActive,
  repeatValue,
  repeatEndDate,
  isRepeatAlways,
  setRemoveRepeatBookingModalActive,
}: EditButtonsProps) {
  const [editBtnTitle, setEditBtnTitle] = useState('Edit');

  const [removeMeetingRoomBooking] = useRemoveMeetingRoomBookingMutation();

  const editedMeetingRoomBooking = useAppSelector(
    (store) => store.meetingRoomsReducer.editedUserMeetingRoom,
  );

  useEffect(() => {
    if (editModeActive && !!editedMeetingRoomBooking) {
      setEditBtnTitle('Save');
    }
  }, [editModeActive, editedMeetingRoomBooking]);

  const { colorScheme } = useTelegram();

  const onEditBtnClickHandler = async () => {
    if (editBtnTitle === 'Save') {
      if (editedMeetingRoomBooking && startTimeValue && endTimeValue) {
        const updatedMeetingRoomBooking = {
          ...editedMeetingRoomBooking,
          userMeetingRoomInfo: {
            ...editedMeetingRoomBooking.userMeetingRoomInfo,
            isRepeatAlways,
            isRepeated,
            repeatEndDate: editedMeetingRoomBooking.userMeetingRoomInfo.isRepeatAlways
              ? null
              : repeatEndDate || null,
            repeatValue,
            startTime: startTimeValue?.format('HH:mm:ss'),
            endTime: endTimeValue?.format('HH:mm:ss'),
          },
        };

        if (
          editedMeetingRoomBooking?.userMeetingRoomInfo.isRepeated &&
          hasOnlyStartAndEndTimeChanged(editedMeetingRoomBooking, updatedMeetingRoomBooking)
        ) {
          setEditRepeatBookingModalActive(true);
        } else {
          onEditHandler();
        }
      }
    }
    setEditModeActive(true);
  };

  const onDeleteBtnHandler = async () => {
    if (isRepeated) {
      setRemoveRepeatBookingModalActive(true);
    } else if (editedMeetingRoomBooking) {
      const result = await removeMeetingRoomBooking({
        id: editedMeetingRoomBooking.userMeetingRoomInfo.id,
      }).unwrap();

      if (result) {
        setDeleteModalActive(true);
      }
    }
  };

  const editBtnTitleText = !isEditMeetingRoomBookingLoading ? editBtnTitle : '';

  return (
    <div css={styles.btnWrapperStyles}>
      <button
        disabled={!!startTimeError || !!endTimeError || isEditMeetingRoomBookingLoading}
        type="button"
        css={styles.editBtnStyles}
        onClick={onEditBtnClickHandler}>
        {isEditMeetingRoomBookingLoading ? (
          <CircularProgress sx={{ color: 'white' }} size={20} />
        ) : (
          editBtnTitleText
        )}
      </button>
      <button
        type="button"
        css={styles.cancelBtnStyles({ colorScheme })}
        onClick={onDeleteBtnHandler}>
        Delete
      </button>
    </div>
  );
}

export default EditButtons;
