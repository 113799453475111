/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const chosenDateWrapperStyles = ({ colorScheme }: { colorScheme: string }) => {
  return css({
    display: 'flex',
    flexDirection: 'row',
    borderRadius: '5px',
    backgroundColor: colorScheme === 'dark' ? '#27292D' : '#E8E8E8',
    padding: '10px',
    marginBottom: '20px',
  });
};

const weekDayTextStyles = ({ colorScheme }: { colorScheme: string }) => {
  return css({
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '24px',
    color: colorScheme === 'dark' ? '#FFFFFF' : '#000000',
    marginBottom: '5px',
  });
};

const dateTextStyles = ({ colorScheme }: { colorScheme: string }) => {
  return css({
    fontWeight: 600,
    fontSize: '10px',
    lineHeight: '12px',
    color: colorScheme === 'dark' ? '#FFFFFF' : '#000000',
  });
};

const headerWrapperStyles = ({ colorScheme }: { colorScheme: string }) => {
  return css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px 10px',
    height: '50px',
    color: colorScheme === 'dark' ? '#FFFFFF' : '#000000',
    backgroundColor: colorScheme === 'dark' ? '#27292D' : '#E8E8E8',
    borderRadius: '5px',
  });
};

const backDateBtnStyles = ({ colorScheme }: { colorScheme: string }) => {
  return css({
    '@media (hover: hover) and (pointer: fine)': {
      '&:hover': {
        background: '#3366FF',
        transition: 'all 0.3s ease-out',
      },
      '&:hover .arrowIcon': {
        stroke: 'white',
        transition: 'all 0.3s ease-out',
      },
    },
    position: 'absolute',
    left: '0px',
    minWidth: '50px',
    minHeight: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '5px',
    cursor: 'pointer',
    background: colorScheme === 'dark' ? '#2D2F34' : '#C4C4C4',
    transition: 'all 0.3s ease-out',
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px',
  });
};

const headerInfoWrapperStyles = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
});

const chosenDateStyles = css({
  '@media (hover: hover) and (pointer: fine)': {
    '&:hover': {
      color: '#3366FF',
      transition: 'all 0.3s ease-out',
      cursor: 'pointer',
    },
  },
  fontSize: '18px',
  fontWeight: 600,
  lineHeight: '22px',
  textAlign: 'center',
  transition: 'all 0.3s ease-out',
});

const spaceTypeStyles = css({
  fontSize: '8px',
  fontWeight: 600,
  textAlign: 'center',
});

const nextDateBtnStyles = ({ colorScheme }: { colorScheme: string }) => {
  return css({
    '@media (hover: hover) and (pointer: fine)': {
      '&:hover': {
        background: '#3366FF',
        transition: 'all 0.3s ease-out',
      },
      '&:hover .arrowIcon': {
        stroke: 'white',
        transition: 'all 0.3s ease-out',
      },
    },
    position: 'absolute',
    right: '0px',
    minWidth: '50px',
    minHeight: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '5px',
    cursor: 'pointer',
    background: colorScheme === 'dark' ? '#2D2F34' : '#C4C4C4',
    transition: 'all 0.3s ease-out',
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',
  });
};

const goBackBtnStyles = ({
  colorScheme,
  isMyBookings,
}: {
  colorScheme: string;
  isMyBookings?: boolean;
}) => {
  let bgColor;
  let marginRight;
  let marginBottom;

  if (isMyBookings) {
    bgColor = colorScheme === 'dark' ? '#1F2023' : '#FFFFFF';
    marginBottom = '20px';
  } else {
    bgColor = colorScheme === 'dark' ? '#2D2F34' : '#C4C4C4';
    marginRight = '20px';
  }
  return css({
    '@media (hover: hover) and (pointer: fine)': {
      '&:hover': {
        background: '#3366FF',
        transition: 'all 0.3s ease-out',
      },
      '&:hover .arrowIcon': {
        stroke: 'white',
        transition: 'all 0.3s ease-out',
      },
    },
    minWidth: '50px',
    minHeight: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '5px',
    border: colorScheme === 'dark' ? '1px solid #383B40' : '1px solid #C4C4C4',
    cursor: 'pointer',
    background: bgColor,
    transition: 'all 0.3s ease-out',
    marginRight,
    marginBottom,
  });
};

const periodInfoWrapperStyles = css({
  display: 'flex',
  flexDirection: 'column',
});

const styles = {
  chosenDateWrapperStyles,
  weekDayTextStyles,
  dateTextStyles,
  headerWrapperStyles,
  backDateBtnStyles,
  headerInfoWrapperStyles,
  chosenDateStyles,
  spaceTypeStyles,
  nextDateBtnStyles,
  goBackBtnStyles,
  periodInfoWrapperStyles,
};

export default styles;
