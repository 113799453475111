import { Box } from '@mui/material';
import React from 'react';
import { useTelegram } from '../hooks/useTelegram';

function AccordionIcon({ onClickHandler }: { onClickHandler: () => void }) {
  const { colorScheme } = useTelegram();
  return (
    <Box onClick={onClickHandler} sx={{ transform: 'rotate(180deg)' }}>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M18.2602 8.20703L12.2836 14.1837C11.5777 14.8895 10.4227 14.8895 9.7169 14.1837L3.74023 8.20703"
          stroke={colorScheme === 'dark' ? '#FFFFFF' : '#000000'}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
}

export default AccordionIcon;
