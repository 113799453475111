import { BookedSpace } from '../store/reducers/officeSpace';

export const useGetBookedSpace = (
  value: string,
  bookedSpaces: BookedSpace[],
  tgUserId: number | undefined,
) => {
  const isMyBook = bookedSpaces.some(
    (s) => s.name === value && s.spaceUser.telegramUserId === tgUserId,
  );

  const isBooked = bookedSpaces.some((s) => s.name === value);

  const bookedSpaceInfo = bookedSpaces.find((s) => s.name === value);
  return { isBooked, bookedSpaceInfo, isMyBook };
};
