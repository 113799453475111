/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/** @jsxImportSource @emotion/react */
import React, { useRef } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Grid } from '@mui/material';
import { MAX_TODAY_TIME } from '../../../constants';
import { useTelegram } from '../../../hooks/useTelegram';
import BackDateIcon from '../../../assets/backDateIcon';
import Calendar from '../calendar';
import NextDateIcon from '../../../assets/nextDateIcon';
import { useSetStorageValue } from '../../../hooks/useSetStorageValue';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside';

import styles from './styles';

interface TableHeaderProps {
  dateState?: string;
  setDateState: React.Dispatch<React.SetStateAction<string | undefined>>;
  setChecked?: React.Dispatch<React.SetStateAction<boolean>>;
  setCalendarActive: React.Dispatch<React.SetStateAction<boolean>>;
  calendarActive: boolean;
  calendarValue?: Dayjs | null;
  setCalendarValue: React.Dispatch<React.SetStateAction<Dayjs | null | undefined>>;
  allMeetingRooms?: boolean;
  meetingRoomName?: string;
}

function TableHeader({
  dateState,
  setDateState,
  setChecked,
  setCalendarActive,
  calendarActive,
  calendarValue,
  setCalendarValue,
  allMeetingRooms,
  meetingRoomName,
}: TableHeaderProps) {
  const setBookingDateStorageValue = useSetStorageValue('bookingDate');

  const { colorScheme } = useTelegram();

  const today = dayjs();

  const todayHours = today.get('hour');

  if (todayHours > MAX_TODAY_TIME) {
    today.add(1, 'day');
  }

  const showBackBtn = dayjs(`${dateState}`)
    .set('hours', 0)
    .set('minutes', 0)
    .set('milliseconds', 0)
    .isAfter(today.set('hours', 0).set('minutes', 0).set('milliseconds', 0));

  const showNextBtn = dayjs(`${dateState}`)
    .set('hours', 0)
    .set('minutes', 0)
    .set('milliseconds', 0)
    .isAfter(today.subtract(1, 'day').set('hours', 0).set('minutes', 0).set('milliseconds', 0));

  const goNextHandler = () => {
    const nextDay = dayjs(dateState).add(1, 'day').format('YYYY-MM-DD');
    setDateState(nextDay);
    setCalendarValue(dayjs(nextDay));
    setBookingDateStorageValue(nextDay);
    if (setChecked) {
      setChecked(false);
    }
  };

  const goBackHandler = () => {
    const prevDay = dayjs(dateState).subtract(1, 'day').format('YYYY-MM-DD');
    setDateState(prevDay);
    setCalendarValue(dayjs(prevDay));
    setBookingDateStorageValue(prevDay);
    if (setChecked) {
      setChecked(false);
    }
  };

  const onDateClickHandler = () => {
    setCalendarActive((prev) => !prev);
  };

  const calendarRef = useRef<HTMLDivElement>(null);

  const onClickOutsideCalendarHandler = (e: Event) => {
    setCalendarActive(false);
  };

  useOnClickOutside(calendarRef, onClickOutsideCalendarHandler);

  const dateString = dayjs(dateState).format('DD.MM.YYYY');

  return (
    <Grid container position="relative" justifyContent="center" mb="20px">
      <Grid item xs={12}>
        <div css={styles.tableHeaderStyles({ colorScheme })}>
          {showBackBtn && (
            <button
              type="button"
              css={styles.backDateBtnStyles({ colorScheme })}
              onClick={goBackHandler}>
              <BackDateIcon />
            </button>
          )}
          <div css={styles.headerInfoWrapperStyles} ref={calendarRef}>
            <p onClick={onDateClickHandler} css={styles.chosenDateStyles}>
              {dateString}
            </p>
            {!allMeetingRooms && <div css={styles.meetingRoomNameStyles}>{meetingRoomName}</div>}
            {calendarActive && (
              <Calendar
                setCalendarActive={setCalendarActive}
                calendarValue={calendarValue}
                setCalendarValue={setCalendarValue}
                setBookingDateStorageValue={setBookingDateStorageValue}
                setChosenDateState={setDateState}
              />
            )}
          </div>
          {showNextBtn && (
            <button
              type="button"
              css={styles.nextDateBtnStyles({ colorScheme })}
              onClick={goNextHandler}>
              <NextDateIcon />
            </button>
          )}
        </div>
      </Grid>
      {allMeetingRooms && (
        <Grid item xs={12} flexDirection="row" display="flex" mt="20px" ml="50px">
          <Grid item xs={4} display="flex" alignItems="center" justifyContent="center">
            <p css={styles.meetingRoomNameTextStyles({ colorScheme })}>MR1</p>
          </Grid>
          <Grid item xs={4} display="flex" alignItems="center" justifyContent="center">
            <p css={styles.meetingRoomNameTextStyles({ colorScheme })}>MR2</p>
          </Grid>
          <Grid item xs={4} display="flex" alignItems="center" justifyContent="center">
            <p css={styles.meetingRoomNameTextStyles({ colorScheme })}>MR3</p>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default TableHeader;
