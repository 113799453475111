/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const wrapperStyles = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '28px',
});

const btnStyles = css({
  '@media (hover: hover) and (pointer: fine)': {
    '&:hover': {
      opacity: '0.7',
      transition: 'all 0.3s ease-out',
    },
  },
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '5px 15px',
  width: '137px',
  height: '36px',
  background: '#3366FF',
  borderRadius: '5px',
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '14px',
  color: '#ffffff',
  marginTop: '15px',
  transition: 'all 0.3s ease-out',
});

const btnWrapperStyles = css({
  marginTop: '10px',
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'center',
});

const dialogStyles = ({ colorScheme }: { colorScheme: string }) => {
  return css({
    '& .MuiPaper-root': {
      borderRadius: '10px',
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.08)',
      background: colorScheme === 'dark' ? '#27292D' : '#FFFFFF',
    },
  });
};

const closeBtnStyles = ({ colorScheme }: { colorScheme: string }) => {
  return css({
    position: 'relative',
    left: '5px',
    bottom: '10px',
    height: '25px',
    width: '25px',
    padding: '2px',
    color: colorScheme === 'dark' ? '#FFFFFF' : '#000000',
  });
};

const headerWrapperStyles = css({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
});

const modalInfoTextStyles = ({ colorScheme }: { colorScheme: string }) => {
  return css({
    minWidth: '255px',
    maxWidth: '290px',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '14px',
    color: colorScheme === 'dark' ? '#FFFFFF' : '#000000',
  });
};

const styles = {
  wrapperStyles,
  btnStyles,
  btnWrapperStyles,
  dialogStyles,
  closeBtnStyles,
  headerWrapperStyles,
  modalInfoTextStyles,
};

export default styles;
