import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface User {
  id: string;
  name: string;
  surname: string;
  telegramUsername: string;
  telegramUserId: number | null;
  createdAt: string;
  updatedAt: string;
  isAuth: boolean;
  email: string | null;
}

interface UserState {
  user: User;
}

const initialState: UserState = {
  user: {
    id: '',
    name: '',
    surname: '',
    telegramUsername: '',
    telegramUserId: null,
    createdAt: '',
    updatedAt: '',
    isAuth: false,
    email: '',
  },
};

interface SetUserPayload {
  user: {
    id: string;
    name: string;
    surname: string;
    telegramUsername: string;
    telegramUserId: number | null;
    createdAt: string;
    updatedAt: string;
  };
}

interface SetUserAuthPayload {
  isAuth: boolean;
  email: string;
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, { payload: { user } }: PayloadAction<SetUserPayload>) => {
      state.user.id = user.id;
      state.user.name = user.name;
      state.user.surname = user.surname;
      state.user.telegramUsername = user.telegramUsername;
      state.user.telegramUserId = user.telegramUserId;
      state.user.createdAt = user.createdAt;
      state.user.updatedAt = user.updatedAt;
    },
    setUserAuth: (state, { payload: { isAuth, email } }: PayloadAction<SetUserAuthPayload>) => {
      state.user.isAuth = isAuth;
      state.user.email = email;
    },
  },
});

export const {
  reducer: userReducer,
  actions: { setUser, setUserAuth },
} = userSlice;
