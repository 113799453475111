/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/** @jsxImportSource @emotion/react */
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import Grid from '@mui/material/Grid';
import { useDispatch } from 'react-redux';
import { Tooltip } from '@mui/material';
import BackArrowIcon from '../../../../assets/backArrowIcon';
import BackDateIcon from '../../../../assets/backDateIcon';
import Calendar from '../../../common/calendar';
import NextDateIcon from '../../../../assets/nextDateIcon';
import { APP_ROUTES, MAX_TODAY_TIME } from '../../../../constants';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { useEnhancedNavigate } from '../../../../hooks/useEnhancedNavigate';
import { useTelegram } from '../../../../hooks/useTelegram';
import { useSetStorageValue } from '../../../../hooks/useSetStorageValue';
import { MeetingRoomUser, setEditedUserMeetingRoom } from '../../../../store/reducers/meetingRooms';

import styles from './styles';
import { useOnClickOutside } from '../../../../hooks/useOnClickOutside';

interface BookMeetingRoomHeaderProps {
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
  checked: boolean;
  chosenDateState?: string;
  isOtherUsersHaveBooking: boolean;
  isAuthorHaveBookings: boolean;
  setChosenDateState: React.Dispatch<React.SetStateAction<string | undefined>>;
  calendarValue: Dayjs | null | undefined;
  setCalendarValue: React.Dispatch<React.SetStateAction<Dayjs | null | undefined>>;
  allDayBookedMeetingRoom: MeetingRoomUser | null;
  setAllDayBookedMeetingRoom: React.Dispatch<React.SetStateAction<MeetingRoomUser | null>>;
  editModeActive: boolean;
  meetingRoomName?: string;
  meetingRoomId?: string;
  bookingDate?: string;
  isMyBookings: boolean;
  isAllMeetingRooms?: boolean;
  setModalActive: React.Dispatch<React.SetStateAction<boolean>>;
  setStartTimeValue: React.Dispatch<React.SetStateAction<Dayjs | null | undefined>>;
  setEndTimeValue: React.Dispatch<React.SetStateAction<Dayjs | null | undefined>>;
  chosenStartTime?: string;
  chosenEndTime?: string;
}

function BookMeetingRoomHeader({
  setChecked,
  checked,
  chosenDateState,
  isOtherUsersHaveBooking,
  isAuthorHaveBookings,
  setChosenDateState,
  calendarValue,
  setCalendarValue,
  allDayBookedMeetingRoom,
  setAllDayBookedMeetingRoom,
  editModeActive,
  meetingRoomName,
  meetingRoomId,
  bookingDate,
  isMyBookings,
  isAllMeetingRooms,
  setModalActive,
  setStartTimeValue,
  setEndTimeValue,
  chosenStartTime,
  chosenEndTime,
}: BookMeetingRoomHeaderProps) {
  const [calendarActive, setCalendarActive] = useState(false);

  const { colorScheme, tgUserId } = useTelegram();

  const { scrollNavigate } = useEnhancedNavigate();

  const editedMeetingRoomBooking = useAppSelector(
    (store) => store.meetingRoomsReducer.editedUserMeetingRoom,
  );

  const allMeetingRoomUsers = useAppSelector(
    (store) => store.meetingRoomsReducer.allMeetingRoomUsers,
  ).filter((u) => u.userMeetingRoomInfo.id !== editedMeetingRoomBooking?.userMeetingRoomInfo.id);

  const dispatch = useDispatch();

  const editedMeetingRoomBookingId = editedMeetingRoomBooking?.id;

  useEffect(() => {
    const minTime = dayjs(`${chosenDateState} 07:00:00`);
    const maxTime = dayjs(`${chosenDateState} 23:00:00`);

    const bookedOtherMeetingRoom = allMeetingRoomUsers.find((u) => {
      const bookedStartTimeDate = dayjs(`${chosenDateState} ${u.userMeetingRoomInfo.startTime}`);
      const bookedEndTimeDate = dayjs(`${chosenDateState} ${u.userMeetingRoomInfo.endTime}`);

      const isStartTimeMinTime =
        minTime.get('hour') === bookedStartTimeDate.get('hour') &&
        minTime.get('minutes') === bookedStartTimeDate.get('minutes');

      const isEndTimeMaxTime =
        maxTime.get('hour') === bookedEndTimeDate.get('hour') &&
        maxTime.get('minutes') === bookedEndTimeDate.get('minutes');

      // @ts-ignore
      const isStartTimeBetween = bookedStartTimeDate.isBetween(minTime, maxTime, '[]');

      // @ts-ignore
      const isEndTimeBetween = bookedEndTimeDate.isBetween(minTime, maxTime, '[]');

      if (
        u.userMeetingRoomInfo.roomId !== meetingRoomId &&
        u.telegramUserId === tgUserId &&
        ((isStartTimeMinTime && isEndTimeMaxTime) || isStartTimeBetween || isEndTimeBetween)
      ) {
        return u;
      }
      return undefined;
    });

    if (bookedOtherMeetingRoom) {
      setAllDayBookedMeetingRoom(bookedOtherMeetingRoom);
    } else {
      setAllDayBookedMeetingRoom(null);
    }
  }, [chosenDateState, allMeetingRoomUsers]);

  const setBookingDateStorageValue = useSetStorageValue('bookingDate');

  const meetingRoomUsers = useAppSelector(
    (store) => store.meetingRoomsReducer.allMeetingRoomUsers,
  ).filter((b) => {
    const roomNumber = meetingRoomName?.split(' ')[2];
    if (b.userMeetingRoomInfo.spaceName === `MR${roomNumber}`) {
      return true;
    }
    return false;
  });

  const today = dayjs();

  const todayHours = today.get('hour');

  if (todayHours > MAX_TODAY_TIME) {
    today.add(1, 'day');
  }

  const showBackBtn =
    dayjs(`${chosenDateState}`)
      .set('hours', 0)
      .set('minutes', 0)
      .set('milliseconds', 0)
      .isAfter(today.set('hours', 0).set('minutes', 0).set('milliseconds', 0)) &&
    !editedMeetingRoomBooking;

  const showNextBtn =
    dayjs(`${chosenDateState}`)
      .set('hours', 0)
      .set('minutes', 0)
      .set('milliseconds', 0)
      .isAfter(today.subtract(1, 'day').set('hours', 0).set('minutes', 0).set('milliseconds', 0)) &&
    !editedMeetingRoomBooking;

  const isUserHaveAllDayBooking = meetingRoomUsers.some(
    (m) =>
      m.telegramUserId === tgUserId &&
      m.userMeetingRoomInfo.startTime === '07:00:00' &&
      m.userMeetingRoomInfo.endTime === '23:00:00',
  );

  const showCheckBox =
    (!isOtherUsersHaveBooking && isAuthorHaveBookings && !isUserHaveAllDayBooking) ||
    meetingRoomUsers.length === 0 ||
    (!isUserHaveAllDayBooking && !isOtherUsersHaveBooking);

  const onDateClickHandler = () => {
    setCalendarActive((prev) => !prev);
  };

  const goNextDateHandler = () => {
    const nextDay = dayjs(chosenDateState).add(1, 'day').format('YYYY-MM-DD');
    setChosenDateState(nextDay);
    setBookingDateStorageValue(nextDay);
    setChecked(false);
  };

  const goPrevDateHandler = () => {
    const prevDay = dayjs(chosenDateState).subtract(1, 'day').format('YYYY-MM-DD');
    setChosenDateState(prevDay);
    setBookingDateStorageValue(prevDay);
    setChecked(false);
  };

  const dateString = dayjs(chosenDateState).format('DD.MM.YYYY');

  let tooltipTitle = '';

  const isTimeEdited = editedMeetingRoomBooking && editModeActive;

  const repeatedBooking = meetingRoomUsers.find(
    (b) =>
      b.userMeetingRoomInfo.isRepeated === true &&
      b.userMeetingRoomInfo.id !== editedMeetingRoomBooking?.userMeetingRoomInfo.id &&
      b.telegramUserId === tgUserId,
  );

  if (isTimeEdited && allDayBookedMeetingRoom) {
    tooltipTitle = `You can’t book this meeting room for all day, because you already have a booking for this day for ${allDayBookedMeetingRoom.userMeetingRoomInfo.spaceName}`;
  } else if (allDayBookedMeetingRoom && !editedMeetingRoomBooking) {
    tooltipTitle = `You can’t book this meeting room for all day, because you already have a booking for this day for ${allDayBookedMeetingRoom.userMeetingRoomInfo.spaceName}`;
  } else if (repeatedBooking && !editedMeetingRoomBooking) {
    tooltipTitle = `You can’t book this meeting room for all day, because you already have the recurring booking`;
  } else if (repeatedBooking && editModeActive) {
    tooltipTitle = `You can’t book this meeting room for all day, because you already have the recurring booking`;
  }

  const isAllDayDisabled =
    (!editModeActive && editedMeetingRoomBooking) ||
    (allDayBookedMeetingRoom && !editedMeetingRoomBooking) ||
    (isTimeEdited && allDayBookedMeetingRoom) ||
    !!repeatedBooking;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const todayDate = dayjs().format('YYYY-MM-DD');

    const minTime = dayjs(`${todayDate} 07:00:00`);
    const maxTime = dayjs(`${todayDate} 23:00:00`);

    if (e.currentTarget.checked) {
      setChecked(true);
      if (isAuthorHaveBookings) {
        setModalActive(true);
        setStartTimeValue(minTime);
        setEndTimeValue(maxTime);
      } else {
        setStartTimeValue(minTime);
        setEndTimeValue(maxTime);
      }
    } else if (editedMeetingRoomBookingId && editedMeetingRoomBooking) {
      setChecked(false);
      setStartTimeValue(
        dayjs(`${todayDate} ${editedMeetingRoomBooking.userMeetingRoomInfo.startTime}`),
      );
      setEndTimeValue(
        dayjs(`${todayDate} ${editedMeetingRoomBooking.userMeetingRoomInfo.endTime}`),
      );
    } else {
      setChecked(false);
      setStartTimeValue(dayjs(`${todayDate} ${chosenStartTime}`));
      setEndTimeValue(dayjs(`${todayDate} ${chosenEndTime}`));
    }
  };

  const goBackHandler = () => {
    dispatch(setEditedUserMeetingRoom({ newEditedUserMeetingRoom: null }));
    if (isAllMeetingRooms) {
      scrollNavigate({
        top: 0,
        left: 0,
        path: `${APP_ROUTES.ALL_MEETING_ROOMS_SCHEDULE_ROUTE}?date=${chosenDateState}`,
        replace: true,
        behavior: 'smooth',
      });
    } else if (!isMyBookings) {
      scrollNavigate({
        top: 0,
        left: 0,
        path: `${APP_ROUTES.MEETING_ROOM_TIMETABLE_PAGE}?date=${chosenDateState}&meetingRoomId=${meetingRoomId}&meetingRoomName=${meetingRoomName}`,
        replace: true,
        behavior: 'smooth',
      });
    } else {
      scrollNavigate({
        top: 0,
        left: 0,
        path: `${APP_ROUTES.MY_BOOKINGS_ROUTE}`,
        replace: true,
        behavior: 'smooth',
      });
    }
  };

  const calendarRef = useRef<HTMLDivElement>(null);

  const onClickOutsideCalendarHandler = (e: Event) => {
    setCalendarActive(false);
  };

  useOnClickOutside(calendarRef, onClickOutsideCalendarHandler);

  return (
    <div>
      <div css={styles.headerWrapperStyles}>
        <Tooltip
          title={isMyBookings ? 'Back to my bookings' : 'Back to the meeting room schedule'}
          placement="top"
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: colorScheme === 'dark' ? '#383B40' : '#ffffff',
                color: colorScheme === 'dark' ? '#ffffff' : '#000000',
                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                fontWeight: 400,
                fontSize: '8px',
                bottom: '10px',
                left: '45px',
                position: 'relative',
              },
            },
            popper: {
              sx: {
                height: '10px',
              },
            },
          }}>
          <button
            type="button"
            css={styles.goBackBtnStyles({ colorScheme })}
            onClick={goBackHandler}>
            <BackArrowIcon />
          </button>
        </Tooltip>

        {showCheckBox && (
          <Tooltip
            title={tooltipTitle}
            enterTouchDelay={0}
            leaveTouchDelay={5000}
            placement="top"
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: colorScheme === 'dark' ? '#383B40' : '#ffffff',
                  color: colorScheme === 'dark' ? '#ffffff' : '#000000',
                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                  fontWeight: 400,
                  fontSize: '8px',
                  bottom: '10px',
                  right: '45px',
                  position: 'relative',
                },
              },
              popper: {
                sx: {
                  height: '10px',
                },
              },
            }}>
            <div css={styles.checkBoxWrapperStyles({ isDisable: Boolean(isAllDayDisabled) })}>
              <label css={styles.checkboxLabelStyles({ colorScheme })}>
                <span
                  css={styles.checkboxTextStyles({
                    colorScheme,
                    checked,
                    isDisable: Boolean(isAllDayDisabled),
                  })}
                  className="checkBoxText">
                  All day
                </span>
                <input
                  className="checkBox"
                  disabled={Boolean(isAllDayDisabled)}
                  css={styles.checkboxStyles({
                    checked,
                    colorScheme,
                    isDisable: Boolean(isAllDayDisabled),
                  })}
                  type="checkbox"
                  checked={checked}
                  onChange={handleChange}
                />
              </label>
            </div>
            {/*  <div css={styles.checkBoxWrapperStyles}>
              <button
                type="button"
                css={styles.checkboxStyles({
                  checked,
                  colorScheme,
                  isDisable: Boolean(isAllDayDisabled),
                })}
                onClick={handleChange}>
                All day
              </button>
            </div> */}
          </Tooltip>
        )}
      </div>
      <Grid container position="relative" justifyContent="center" mb="20px">
        <Grid item xs={12}>
          <div css={styles.tableHeaderStyles({ colorScheme })}>
            {showBackBtn && (
              <button
                type="button"
                css={styles.backDateBtnStyles({ colorScheme })}
                onClick={goPrevDateHandler}>
                <BackDateIcon />
              </button>
            )}
            <div css={styles.headerInfoWrapperStyles} ref={calendarRef}>
              {editedMeetingRoomBooking ? (
                <p css={styles.chosenDateStyles}>{dateString}</p>
              ) : (
                <p onClick={onDateClickHandler} css={styles.chosenDateStyles}>
                  {dateString}
                </p>
              )}
              <div css={styles.meetingRoomNameStyles}>{meetingRoomName}</div>
              {calendarActive && (
                <Calendar
                  setCalendarActive={setCalendarActive}
                  calendarValue={calendarValue}
                  setCalendarValue={setCalendarValue}
                  setChosenDateState={setChosenDateState}
                  setBookingDateStorageValue={setBookingDateStorageValue}
                />
              )}
            </div>
            {showNextBtn && (
              <button
                type="button"
                css={styles.nextDateBtnStyles({ colorScheme })}
                onClick={goNextDateHandler}>
                <NextDateIcon />
              </button>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default BookMeetingRoomHeader;
