/** @jsxImportSource @emotion/react */
import { SerializedStyles } from '@emotion/react';
import React from 'react';

function CheckboxIcon({ iconStyles }: { iconStyles: SerializedStyles }) {
  return (
    <svg
      css={iconStyles}
      width="10"
      height="7"
      viewBox="0 0 10 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 3.4873L3.5127 6L8.91889 1"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CheckboxIcon;
