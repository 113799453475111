/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import facepaint from 'facepaint';
import { BREAKPOINTS } from '../../../constants';

const mq = facepaint(BREAKPOINTS.map((bp) => `@media (min-width: ${bp}px)`));

const wrapperStyles = ({ colorScheme }: { colorScheme: string }) => {
  return css(
    mq({
      padding: '20px 20px 20px 20px',
      height: '100vh',
      background: colorScheme === 'dark' ? '#1F2023' : '#FFFFFF',
      overflowY: 'scroll',
      overflowX: 'hidden',
    }),
  );
};

const preloaderWrapperStyles = css({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const goBackToMyBookingsBtnStyles = ({
  colorScheme,
  isMyBookings,
}: {
  colorScheme: string;
  isMyBookings?: boolean;
}) => {
  let bgColor;
  let marginRight;
  let marginBottom;

  if (isMyBookings) {
    bgColor = colorScheme === 'dark' ? '#1F2023' : '#FFFFFF';
    marginBottom = '20px';
  } else {
    bgColor = colorScheme === 'dark' ? '#2D2F34' : '#C4C4C4';
    marginRight = '20px';
  }
  return css({
    '@media (hover: hover) and (pointer: fine)': {
      '&:hover': {
        background: '#3366FF',
        transition: 'all 0.3s ease-out',
      },
      '&:hover .arrowIcon': {
        stroke: 'white',
        transition: 'all 0.3s ease-out',
      },
    },
    minWidth: '50px',
    minHeight: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '5px',
    border: colorScheme === 'dark' ? '1px solid #383B40' : '1px solid #C4C4C4',
    cursor: 'pointer',
    background: bgColor,
    transition: 'all 0.3s ease-out',
    marginRight,
    marginBottom,
  });
};

const styles = {
  preloaderWrapperStyles,
  wrapperStyles,
  goBackToMyBookingsBtnStyles,
};

export default styles;
