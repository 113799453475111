/** @jsxImportSource @emotion/react */
import React from 'react';
import { SerializedStyles } from '@emotion/react';
import { useTelegram } from '../hooks/useTelegram';

function RemoveIcon({ removeIconStyles }: { removeIconStyles: SerializedStyles }) {
  const { colorScheme } = useTelegram();
  return (
    <svg
      css={removeIconStyles}
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.875 4.23344C12.5162 3.99969 10.1433 3.87927 7.7775 3.87927C6.375 3.87927 4.9725 3.95011 3.57 4.09177L2.125 4.23344"
        className="removeIcon"
        stroke={colorScheme === 'dark' ? '#FFFFFF' : '#000000'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.021 3.52038L6.17683 2.59246C6.29016 1.91954 6.37516 1.41663 7.57225 1.41663H9.42808C10.6252 1.41663 10.7172 1.94788 10.8235 2.59954L10.9793 3.52038"
        className="removeIcon"
        stroke={colorScheme === 'dark' ? '#FFFFFF' : '#000000'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3526 6.47461L12.8922 13.6075C12.8143 14.7196 12.7505 15.5838 10.7743 15.5838H6.22677C4.25052 15.5838 4.18677 14.7196 4.10885 13.6075L3.64844 6.47461"
        className="removeIcon"
        stroke={colorScheme === 'dark' ? '#FFFFFF' : '#000000'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.31738 11.6875H9.67613"
        className="removeIcon"
        stroke={colorScheme === 'dark' ? '#FFFFFF' : '#000000'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.729 8.85413H10.2707"
        className="removeIcon"
        stroke={colorScheme === 'dark' ? '#FFFFFF' : '#000000'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default RemoveIcon;
