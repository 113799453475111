/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { BookInfoModal } from './bookInfoModal';
import {
  useBookOfficeSpaceMutation,
  useEditOfficeSpaceMutation,
  useLazyGetBookedOfficeSpacesQuery,
  useLazyGetAllMeetingRoomsUsersQuery,
  useRemoveOfficeSpaceMutation,
  useLazyGetParticularBookingQuery,
} from '../../../api/rootApi';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useTelegram } from '../../../hooks/useTelegram';
import { DeleteInfoModal } from './deleteInfoModal';
import {
  setBookedSpaces,
  setEditedUserSpace,
  setOfficeSpace,
} from '../../../store/reducers/officeSpace';
import FullOffice from './fullOffice';
import MapHeader from './header';
import { ConfirmModal } from './confirmModal';

import styles from './styles';

function OfficeMap() {
  const [modalActive, setModalActive] = useState(false);
  const [editWorkPlaceActive, setEditWorkPlaceActive] = useState(false);
  const [deleteModalActive, setDeleteModalActive] = useState(false);
  const [startDateState, setStartDateState] = useState<string | undefined>(undefined);
  const [endDateState, setEndDateState] = useState<string | undefined>(undefined);
  const [meetingRoomDateState, setMeetingRoomDateState] = useState<string | undefined>(undefined);
  const [isMyBooking, setIsMyBooking] = useState(false);
  const [isEditClosed, setIsEditClosed] = useState(false);
  const [otherUsersHaveBookingsModalActive, setOtherUsersHaveBookingsModalActive] = useState(false);
  const [spaceIdState, setSpaceIdState] = useState<string | null>(null);

  const [removeUserSpace] = useRemoveOfficeSpaceMutation();

  const [queryParams] = useSearchParams();

  const { tg, queryId, tgUserId, colorScheme } = useTelegram();

  const chosenStartDate = queryParams.get('startDate') ?? undefined;

  const chosenEndDate = queryParams.get('endDate') ?? undefined;

  const chosenMeetingRoomDate = queryParams.get('meetingRoomDate') ?? undefined;

  const isMeetingRoom = queryParams.get('isMeetingRoom') ?? undefined;

  const periodActive = queryParams.get('periodActive') ?? undefined;

  const isClosed = queryParams.get('isClosed') ?? undefined;

  const isEdited = queryParams.get('isEdited') ?? undefined;

  const isMyBookings = Boolean(queryParams.get('isMyBookings'));

  const dispatch = useDispatch();

  const editSpaceId = useAppSelector((store) => store.officeSpaceReducer.editedUserSpace?.id);

  const editSpaceName = useAppSelector((store) => store.officeSpaceReducer.editedUserSpace?.name);

  const isEditSpaceRepeatAlways = useAppSelector(
    (store) => store.officeSpaceReducer.editedUserSpace?.isRepeatAlways,
  );

  const userInfo = useAppSelector((store) => store.userReducer.user);

  useEffect(() => {
    setStartDateState(chosenStartDate);
    setEndDateState(chosenEndDate);
    setIsEditClosed(Boolean(isClosed));
    setMeetingRoomDateState(chosenMeetingRoomDate);
    if (editSpaceId && chosenStartDate && editSpaceName) {
      setEditWorkPlaceActive(true);
      setSpaceIdState(editSpaceId);
      if (!periodActive) {
        dispatch(
          setEditedUserSpace({
            newEditedUserSpace: {
              id: editSpaceId,
              name: editSpaceName,
              startDate: chosenStartDate,
              endDate: chosenEndDate,
              isRepeatAlways: Boolean(isEditSpaceRepeatAlways),
            },
          }),
        );
      }
    }
    if (isMeetingRoom) {
      dispatch(setBookedSpaces({ newBookedSpaces: [] }));
    }
  }, []);

  const [getBookedOfficeSpaces, { isFetching }] = useLazyGetBookedOfficeSpacesQuery();

  useEffect(() => {
    if (startDateState) {
      getBookedOfficeSpaces({
        startDate: startDateState,
        endDate: endDateState,
      });

      setIsMyBooking(false);
    }
  }, [startDateState, endDateState]);

  const [getParticularBooking, { data: particularBookingData }] =
    useLazyGetParticularBookingQuery();

  useEffect(() => {
    if (spaceIdState === editSpaceId && !isEdited) {
      getParticularBooking({ id: spaceIdState });
    }
  }, [spaceIdState]);

  useEffect(() => {
    if (spaceIdState && particularBookingData && editWorkPlaceActive && !isEdited) {
      if (!periodActive && !isClosed) {
        setStartDateState(particularBookingData.startDate);
        if (particularBookingData.endDate) {
          setEndDateState(particularBookingData.endDate);
        } else {
          setEndDateState(undefined);
        }
      }
    }
  }, [spaceIdState, particularBookingData]);

  useEffect(() => {
    if (particularBookingData && spaceIdState && editWorkPlaceActive && !isEdited) {
      dispatch(
        setEditedUserSpace({
          newEditedUserSpace: {
            id: particularBookingData.userSpaceId,
            name: particularBookingData.name,
            startDate: particularBookingData.startDate,
            endDate: particularBookingData.endDate,
            isRepeatAlways: particularBookingData.isRepeatAlways,
          },
        }),
      );
    }
  }, [spaceIdState, particularBookingData]);

  const bookedSpaces = useAppSelector((store) => store.officeSpaceReducer.bookedSpaces);

  const userBooking = bookedSpaces.find((s) => {
    if (s.spaceUser.telegramUserId === tgUserId) {
      return s;
    }
    return undefined;
  });

  useEffect(() => {
    if (
      userBooking &&
      userBooking.spaceUser.telegramUserId === tgUserId &&
      userBooking.userSpaceId !== editSpaceId &&
      !isEditClosed
    ) {
      setModalActive(true);
      setEditWorkPlaceActive(false);
    }
  }, [userBooking, editSpaceId, startDateState]);

  const [getAllMeetingRoomUsers, { isFetching: isFetchingGetAllMeetingRoomUsers }] =
    useLazyGetAllMeetingRoomsUsersQuery();

  useEffect(() => {
    if (Boolean(isMeetingRoom) && meetingRoomDateState) {
      getAllMeetingRoomUsers({ date: meetingRoomDateState });
    }
  }, [meetingRoomDateState]);

  const chosenSpace = useAppSelector((store) => store.officeSpaceReducer.chosenSpace);

  const [bookOfficeSpace] = useBookOfficeSpaceMutation();

  const [editOfficeSpace] = useEditOfficeSpaceMutation();

  const onSendData = useCallback(async () => {
    if (
      (editWorkPlaceActive || editSpaceId) &&
      editSpaceId &&
      queryId &&
      chosenSpace &&
      startDateState
    ) {
      const data = {
        queryId,
        spaceName: chosenSpace,
        editSpaceId,
        startDate: startDateState,
        endDate: endDateState,
      };

      await editOfficeSpace(data);

      dispatch(setEditedUserSpace({ newEditedUserSpace: null }));
      dispatch(setOfficeSpace({ newSpace: '' }));
    }

    if (tgUserId && queryId && startDateState && chosenSpace && !isClosed && !editWorkPlaceActive) {
      const data = {
        queryId,
        spaceName: chosenSpace,
        startDate: startDateState,
        endDate: endDateState,
        telegramUserId: tgUserId,
      };

      await bookOfficeSpace(data);
      dispatch(setOfficeSpace({ newSpace: '' }));
    }
  }, [chosenSpace, editWorkPlaceActive, startDateState, editSpaceId]);

  useEffect(() => {
    tg.onEvent('mainButtonClicked', onSendData);
    return () => {
      tg.offEvent('mainButtonClicked', onSendData);
    };
  }, [onSendData]);

  useEffect(() => {
    if (editWorkPlaceActive && editSpaceId) {
      if (isMyBooking) {
        tg.MainButton.setParams({
          text: `Save`,
        });
      } else {
        tg.MainButton.setParams({
          text: `Change to ${chosenSpace}`,
        });
      }
    } else {
      tg.MainButton.setParams({
        text: `Book ${chosenSpace}`,
      });
    }
  }, [chosenSpace, editWorkPlaceActive, editSpaceId, isMyBooking]);

  useEffect(() => {
    if (!chosenSpace) {
      tg.MainButton.hide();
    } else {
      tg.MainButton.show();
    }
  }, [chosenSpace]);

  const onDeleteUserSpaceHandler = async () => {
    if (userBooking) {
      const result = await removeUserSpace({ userSpaceId: userBooking.userSpaceId }).unwrap();
      if (result) {
        setModalActive(false);
        setDeleteModalActive(true);
      }
    }
  };

  const isLoading = isMeetingRoom ? isFetchingGetAllMeetingRoomUsers : isFetching;

  const cancelConfirmModal = () => {
    setOtherUsersHaveBookingsModalActive(false);
  };

  return (
    <div css={styles.wrapperStyles({ colorScheme })}>
      {isLoading && (
        <div css={styles.preloaderWrapperStyles}>
          <CircularProgress />
        </div>
      )}
      {!isLoading && (
        <>
          <MapHeader
            isMyBookings={isMyBookings}
            setEditWorkPlaceActive={setEditWorkPlaceActive}
            periodActive={Boolean(periodActive)}
            startDateState={startDateState}
            setStartDateState={setStartDateState}
            endDateState={endDateState}
            isMeetingRoom={Boolean(isMeetingRoom)}
            meetingRoomDateState={meetingRoomDateState}
            setMeetingRoomDateState={setMeetingRoomDateState}
            editWorkPlaceActive={editWorkPlaceActive}
            setIsEditClosed={setIsEditClosed}
          />
          <FullOffice
            setEditWorkPlaceActive={setEditWorkPlaceActive}
            setModalActive={setModalActive}
            editWorkPlaceActive={editWorkPlaceActive}
            chosenMeetingRoomDate={meetingRoomDateState}
            isMeetingRoom={Boolean(isMeetingRoom)}
            setIsMyBooking={setIsMyBooking}
            startDateState={startDateState}
            endDateState={endDateState}
            userInfo={userInfo}
            setStartDateState={setStartDateState}
            setEndDateState={setEndDateState}
            setOtherUsersHaveBookingsModalActive={setOtherUsersHaveBookingsModalActive}
            setSpaceIdState={setSpaceIdState}
            setIsEditClosed={setIsEditClosed}
          />
          {userBooking && !isMeetingRoom && (
            <BookInfoModal
              setEditWorkPlaceActive={setEditWorkPlaceActive}
              open={modalActive}
              setOpen={setModalActive}
              startDateState={startDateState}
              endDateState={endDateState}
              onDeleteWorkPlaceHandler={onDeleteUserSpaceHandler}
              spaceName={userBooking.name}
              isMyBooking={isMyBooking}
              setIsMyBooking={setIsMyBooking}
              setStartDateState={setStartDateState}
              setEndDateState={setEndDateState}
              setSpaceIdState={setSpaceIdState}
              setIsEditClosed={setIsEditClosed}
            />
          )}
          {deleteModalActive && (
            <DeleteInfoModal open={deleteModalActive} setOpen={setDeleteModalActive} />
          )}
          {editSpaceName && (
            <ConfirmModal
              open={otherUsersHaveBookingsModalActive}
              setOpen={setOtherUsersHaveBookingsModalActive}
              onCancel={cancelConfirmModal}
              onConfirm={cancelConfirmModal}
              spaceName={editSpaceName}
            />
          )}
        </>
      )}
    </div>
  );
}

export default OfficeMap;
