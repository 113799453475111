/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const tableHeaderStyles = ({ colorScheme }: { colorScheme: string }) => {
  return css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px 10px',
    height: '50px',
    color: colorScheme === 'dark' ? '#FFFFFF' : '#000000',
    backgroundColor: colorScheme === 'dark' ? '#27292D' : '#E8E8E8',
    borderRadius: '5px',
  });
};

const backDateBtnStyles = ({ colorScheme }: { colorScheme: string }) => {
  return css({
    '@media (hover: hover) and (pointer: fine)': {
      '&:hover': {
        background: '#3366FF',
        transition: 'all 0.3s ease-out',
      },
      '&:hover .arrowIcon': {
        stroke: 'white',
        transition: 'all 0.3s ease-out',
      },
    },
    position: 'absolute',
    left: '0px',
    minWidth: '50px',
    minHeight: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '5px',
    cursor: 'pointer',
    background: colorScheme === 'dark' ? '#2D2F34' : '#C4C4C4',
    transition: 'all 0.3s ease-out',
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px',
  });
};

const headerInfoWrapperStyles = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
});

const chosenDateStyles = css({
  '@media (hover: hover) and (pointer: fine)': {
    '&:hover': {
      color: '#3366FF',
      transition: 'all 0.3s ease-out',
      cursor: 'pointer',
    },
  },
  fontSize: '18px',
  fontWeight: 600,
  lineHeight: '22px',
  textAlign: 'center',
  transition: 'all 0.3s ease-out',
});

const meetingRoomNameStyles = css({
  fontSize: '8px',
  fontWeight: 600,
  textAlign: 'center',
});

const nextDateBtnStyles = ({ colorScheme }: { colorScheme: string }) => {
  return css({
    '@media (hover: hover) and (pointer: fine)': {
      '&:hover': {
        background: '#3366FF',
        transition: 'all 0.3s ease-out',
      },
      '&:hover .arrowIcon': {
        stroke: 'white',
        transition: 'all 0.3s ease-out',
      },
    },
    position: 'absolute',
    right: '0px',
    minWidth: '50px',
    minHeight: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '5px',
    cursor: 'pointer',
    background: colorScheme === 'dark' ? '#2D2F34' : '#C4C4C4',
    transition: 'all 0.3s ease-out',
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',
  });
};

const meetingRoomNameTextStyles = ({ colorScheme }: { colorScheme: string }) => {
  return css({
    color: colorScheme === 'dark' ? '#FFFFFF' : '#000000',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '22px',
    textAlign: 'center',
  });
};

const styles = {
  tableHeaderStyles,
  backDateBtnStyles,
  headerInfoWrapperStyles,
  chosenDateStyles,
  meetingRoomNameStyles,
  nextDateBtnStyles,
  meetingRoomNameTextStyles,
};

export default styles;
