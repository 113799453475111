/** @jsxImportSource @emotion/react */
import React, { useRef } from 'react';
import { useDraggable } from 'react-use-draggable-scroll';
import { useTelegram } from '../../../../hooks/useTelegram';
import { User } from '../../../../store/reducers/user';
import OfficeBottomPart from '../officeBottomPart';
import OfficeTopPart from '../officeTopPart';

import styles from './styles';

interface FullOfficeProps {
  setEditWorkPlaceActive: React.Dispatch<React.SetStateAction<boolean>>;
  setModalActive: React.Dispatch<React.SetStateAction<boolean>>;
  editWorkPlaceActive: boolean;
  chosenMeetingRoomDate?: string;
  isMeetingRoom: boolean;
  setIsMyBooking: React.Dispatch<React.SetStateAction<boolean>>;
  startDateState?: string;
  endDateState?: string;
  userInfo: User | null;
  setStartDateState: React.Dispatch<React.SetStateAction<string | undefined>>;
  setEndDateState: React.Dispatch<React.SetStateAction<string | undefined>>;
  setOtherUsersHaveBookingsModalActive: React.Dispatch<React.SetStateAction<boolean>>;
  setSpaceIdState: React.Dispatch<React.SetStateAction<string | null>>;
  setIsEditClosed: React.Dispatch<React.SetStateAction<boolean>>;
}

function FullOffice({
  setEditWorkPlaceActive,
  setModalActive,
  editWorkPlaceActive,
  chosenMeetingRoomDate,
  isMeetingRoom,
  setIsMyBooking,
  startDateState,
  endDateState,
  userInfo,
  setStartDateState,
  setEndDateState,
  setOtherUsersHaveBookingsModalActive,
  setSpaceIdState,
  setIsEditClosed,
}: FullOfficeProps) {
  const { colorScheme } = useTelegram();

  const ref = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;

  const { events } = useDraggable(ref, {
    applyRubberBandEffect: true,
  });

  return (
    <div {...events} ref={ref} css={styles.officeMapWrapperStyles({ colorScheme })}>
      <OfficeTopPart
        chosenMeetingRoomDate={chosenMeetingRoomDate}
        isMeetingRoom={Boolean(isMeetingRoom)}
      />
      <OfficeBottomPart
        setSpaceIdState={setSpaceIdState}
        userInfo={userInfo}
        startDateState={startDateState}
        endDateState={endDateState}
        setEditWorkPlaceActive={setEditWorkPlaceActive}
        setModalActive={setModalActive}
        editWorkPlaceActive={editWorkPlaceActive}
        chosenMeetingRoomDate={chosenMeetingRoomDate}
        isMeetingRoom={Boolean(isMeetingRoom)}
        setIsMyBooking={setIsMyBooking}
        setStartDateState={setStartDateState}
        setEndDateState={setEndDateState}
        setOtherUsersHaveBookingsModalActive={setOtherUsersHaveBookingsModalActive}
        setIsEditClosed={setIsEditClosed}
      />
      <div css={styles.windowWrapperStyles}>
        <div css={styles.windowStyles({ colorScheme })}>Window</div>
      </div>
    </div>
  );
}

export default FullOffice;
