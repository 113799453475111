/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const inputStyles = css({
  marginTop: '10px',
  width: '100%',
});

const styles = {
  inputStyles,
};

export default styles;
