/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import facepaint from 'facepaint';
import { BREAKPOINTS } from '../../../../constants';

const mq = facepaint(BREAKPOINTS.map((bp) => `@media (min-width: ${bp}px)`));

const headerWrapperStyles = css({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  height: '50px',
  marginBottom: '20px',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'relative',
});

const goBackBtnStyles = ({ colorScheme }: { colorScheme: string }) => {
  return css({
    '@media (hover: hover) and (pointer: fine)': {
      '&:hover': {
        background: '#3366FF',
        transition: 'all 0.3s ease-out',
      },
      '&:hover .arrowIcon': {
        stroke: 'white',
        transition: 'all 0.3s ease-out',
      },
    },
    minWidth: '50px',
    minHeight: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '5px',
    border: colorScheme === 'dark' ? '1px solid #383B40' : '1px solid #C4C4C4',
    cursor: 'pointer',
    background: colorScheme === 'dark' ? '#1F2023' : '#FFFFFF',
    transition: 'all 0.3s ease-out',
  });
};

const checkboxStyles = ({
  checked,
  colorScheme,
  isDisable,
}: {
  checked: boolean;
  colorScheme: string;
  isDisable: boolean;
}) => {
  let textColor = colorScheme === 'dark' ? '#FFFFFF' : '#000000';
  let bgColor;
  const hoverObj = {
    background: '#3366FF',
    color: '#FFFFFF',
    transition: 'all 0.3s ease-out',
  };

  if (colorScheme === 'dark') {
    bgColor = '#1F2023';
  } else {
    bgColor = '#FFFFFF';
  }

  if (checked) {
    textColor = '#FFFFFF';
    bgColor = '#3366FF';
  }

  if (isDisable) {
    bgColor = colorScheme === 'dark' ? '#383B40' : '#C4C4C4';
    textColor = colorScheme === 'dark' ? '#FFFFFF' : '#000000';
  }

  return css({
    '@media (hover: hover) and (pointer: fine)': {
      '&:hover': !isDisable ? hoverObj : {},
    },
    '-webkit-appearance': 'none',
    appearance: 'none',
    minWidth: '50px',
    minHeight: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'all 0.3s ease-out',
    color: textColor,
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '8px',
    lineHeight: '10px',
    border: colorScheme === 'dark' ? '1px solid #383B40' : '1px solid #C4C4C4',
    background: bgColor,
  });
};

const checkBoxWrapperStyles = ({ isDisable }: { isDisable: boolean }) => {
  const textHoverObj = {
    color: '#FFFFFF',
    transition: 'all 0.3s ease-out',
  };

  const hoverObj = {
    color: '#FFFFFF',
    transition: 'all 0.3s ease-out',
    background: '#3366FF',
  };
  return css({
    '@media (hover: hover) and (pointer: fine)': {
      '&:hover .checkBoxText': !isDisable ? textHoverObj : {},
      '&:hover .checkBox': !isDisable ? hoverObj : {},
    },
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '10px 0px',
    justifyContent: 'flex-end',
  });
};

const checkboxTextStyles = ({
  colorScheme,
  checked,
  isDisable,
}: {
  colorScheme: string;
  checked: boolean;
  isDisable: boolean;
}) => {
  let textColor = colorScheme === 'dark' ? '#FFFFFF' : '#000000';

  if (checked) {
    textColor = '#FFFFFF';
  }

  if (isDisable) {
    textColor = colorScheme === 'dark' ? '#FFFFFF' : '#000000';
  }
  return css(
    mq({
      position: 'absolute',
      left: [
        '8px',
        'calc(50px / 2 - 27px / 2)',
        'calc(50px / 2 - 27px / 2)',
        'calc(50px / 2 - 27px / 2)',
      ],
      color: textColor,
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '8px',
      lineHeight: '10px',
      transition: 'all 0.3s ease-out',
    }),
  );
};

const checkboxLabelStyles = ({ colorScheme }: { colorScheme: string }) => {
  return css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    cursor: 'pointer',
  });
};

const styles = {
  checkboxStyles,
  headerWrapperStyles,
  goBackBtnStyles,
  checkBoxWrapperStyles,
  checkboxTextStyles,
  checkboxLabelStyles,
};

export default styles;
