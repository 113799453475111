/** @jsxImportSource @emotion/react */
import React from 'react';

import styles from './styles';

function ConfirmGoogleLoginLayout() {
  return (
    <div css={styles.wrapperStyles}>
      <h3 css={styles.headerTextStyles}>
        Login was successful, return to the bot using the link below
      </h3>
      <a href="https://t.me/sunmait_booking_test_bot">Go back to the bot</a>
    </div>
  );
}

export default ConfirmGoogleLoginLayout;
