import { TelegramWebApps } from 'telegram-webapps-types';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_V1_ROUTE } from '../constants';

declare global {
  interface Window {
    Telegram: TelegramWebApps.SDK;
  }
}

const tg = window.Telegram.WebApp;

export const baseQuery = fetchBaseQuery({
  baseUrl: API_V1_ROUTE,
  credentials: 'include',
  mode: 'cors',
  prepareHeaders: (headers, { getState }) => {
    const telegramUserId = tg.initDataUnsafe?.user?.id;

    if (telegramUserId) {
      headers.set('Authorization', `${telegramUserId}`);
    }

    return headers;
  },
});
