/** @jsxImportSource @emotion/react */
// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuidv4 } from 'uuid';
import React, { ChangeEvent, useEffect } from 'react';
import dayjs from 'dayjs';
import { Accordion, AccordionSummary, Divider } from '@mui/material';
import AccordionIcon from '../../../../assets/accordionIcon';
import { useTelegram } from '../../../../hooks/useTelegram';
import Checkbox from '../../../common/checkbox';
import BookingsAccordionDetails from './accordionDetails';
import { SelectedItem } from '..';
import { MyBooking } from '../../../../store/reducers/allBookings';

import styles from './styles';

const isBetween = require('dayjs/plugin/isBetween');

dayjs.extend(isBetween);

interface BookingsAccordionProps {
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  setIsChecked: React.Dispatch<React.SetStateAction<boolean>>;
  expanded: boolean;
  isChecked: boolean;
  accordionTitle: string;
  accordionItems: any[];
  setSelectedItems?: React.Dispatch<React.SetStateAction<SelectedItem[]>>;
  selectedItems: SelectedItem[];
  removeModeActive: boolean;
  setRemoveModeActive: React.Dispatch<React.SetStateAction<boolean>>;
  otherRemoveActive: boolean;
  setRemoveRepeatBookingActive: React.Dispatch<React.SetStateAction<boolean>>;
  setRemoveRepeatBooking: React.Dispatch<React.SetStateAction<MyBooking | null>>;
  loadMoreHandler: () => void;
  showLoadMoreBtn: boolean;
}

function BookingsAccordion({
  setExpanded,
  setIsChecked,
  expanded,
  isChecked,
  accordionTitle,
  accordionItems,
  setSelectedItems,
  selectedItems,
  removeModeActive,
  setRemoveModeActive,
  otherRemoveActive,
  setRemoveRepeatBookingActive,
  setRemoveRepeatBooking,
  loadMoreHandler,
  showLoadMoreBtn,
}: BookingsAccordionProps) {
  const { colorScheme } = useTelegram();

  const toggleAccordion = () => {
    setExpanded((prev) => !prev);
  };

  const accordionItemsIds: SelectedItem[] = accordionItems.map((i) => {
    if (i.isMeetingRoom) {
      return {
        id: i.id,
        date: i.date,
      };
    }
    return {
      id: i.id,
      date: i.startDate,
    };
  });

  const checkBoxHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setIsChecked((prev) => !prev);
    if (
      e.currentTarget.checked &&
      selectedItems.length !== accordionItemsIds.length &&
      setSelectedItems
    ) {
      setRemoveModeActive(true);
      setSelectedItems(accordionItemsIds);
    }
  };

  useEffect(() => {
    if (selectedItems.length === accordionItems.length && !isChecked && removeModeActive) {
      setIsChecked(false);
      setRemoveModeActive(false);
      if (setSelectedItems) {
        setSelectedItems([]);
      }
    }
  }, [isChecked, removeModeActive]);

  const accordionDetails = accordionItems.map((i) => {
    return (
      <BookingsAccordionDetails
        setRemoveRepeatBooking={setRemoveRepeatBooking}
        setRemoveRepeatBookingActive={setRemoveRepeatBookingActive}
        otherRemoveActive={otherRemoveActive}
        removeModeActive={removeModeActive}
        setSelectedItems={setSelectedItems}
        key={uuidv4()}
        item={i}
        isChecked={isChecked}
        setIsChecked={setIsChecked}
        selectedItems={selectedItems}
      />
    );
  });

  return (
    <Accordion disableGutters css={styles.accordionStyles({ colorScheme })} expanded={expanded}>
      <AccordionSummary
        css={styles.accordionSummaryStyles}
        expandIcon={<AccordionIcon onClickHandler={toggleAccordion} />}>
        <p css={styles.accordionTitleStyles({ colorScheme })}>{accordionTitle}</p>
        <Divider css={styles.dividerStyles({ colorScheme })} orientation="vertical" />
        <Checkbox
          label="All Bookings"
          showLabel
          isChecked={isChecked}
          onChangeHandler={checkBoxHandler}
        />
      </AccordionSummary>
      {accordionDetails}
      {showLoadMoreBtn && (
        <div css={styles.loadMoreBtnWrapperStyles}>
          <button type="button" css={styles.editBtnStyles} onClick={loadMoreHandler}>
            Load More
          </button>
        </div>
      )}
    </Accordion>
  );
}

export default BookingsAccordion;
