/* eslint-disable @typescript-eslint/no-magic-numbers */
import dayjs, { Dayjs } from 'dayjs';
import { MeetingRoomUser } from '../store/reducers/meetingRooms';

const duration = require('dayjs/plugin/duration');

dayjs.extend(duration);

const weekOfYear = require('dayjs/plugin/weekOfYear');

dayjs.extend(weekOfYear);

const weekday = require('dayjs/plugin/weekday');

dayjs.extend(weekday);

export const generateWeekViewCoordinates = (
  meetingRoomUser: MeetingRoomUser,
  startDate: Dayjs,
  allMeetingRooms?: boolean,
  bookedMeetingRoomName?: string,
) => {
  const start = dayjs(
    `${meetingRoomUser.userMeetingRoomInfo.date} ${meetingRoomUser.userMeetingRoomInfo.startTime}`,
  );

  const end = dayjs(
    `${meetingRoomUser.userMeetingRoomInfo.date} ${meetingRoomUser.userMeetingRoomInfo.endTime}`,
  );

  // @ts-ignore
  const timeDuration = dayjs.duration(end.diff(start));

  const startDateTime = dayjs(
    `${meetingRoomUser.userMeetingRoomInfo.date} ${meetingRoomUser.userMeetingRoomInfo.startTime}`,
  ).format('HH:mm:ss');

  const startDateHour = Number(startDateTime.split(':')[0]);

  const startDateMinute = Number(startDateTime.split(':')[1]);

  // Calculating Top

  const top = `calc((100% / 16 * ${startDateHour - 7}) ${
    startDateMinute ? `+ (100% / 16 / 60 * ${startDateMinute})` : ''
  })`;

  // Calculating Left
  let left = '50px';

  if (allMeetingRooms && bookedMeetingRoomName === 'MR1') {
    left = '50px';
  } else if (allMeetingRooms && bookedMeetingRoomName === 'MR2') {
    left = 'calc(((100% - 50px) / 3) + 50px)';
  } else if (allMeetingRooms && bookedMeetingRoomName === 'MR3') {
    left = 'calc(((100% - 50px) / 3 * 2) + 50px)';
  }

  // Calculating height
  const timeFactor = timeDuration.hours() + timeDuration.minutes() / 60;
  const height = `calc(100% / 16 * ${timeFactor})`;

  // Calculating Width
  let width = 'calc(100% - 50px)';

  if (allMeetingRooms) {
    width = 'calc((100% - 50px) / 3)';
  }

  return {
    top,
    left,
    height,
    width,
  };
};
