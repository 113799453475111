/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import facepaint from 'facepaint';
import { BREAKPOINTS } from '../../../../constants';

const mq = facepaint(BREAKPOINTS.map((bp) => `@media (min-width: ${bp}px)`));

const meetingRoomStyles = ({
  isMeetingRoom,
  colorScheme,
  isMRDisable,
}: {
  isMeetingRoom: boolean;
  colorScheme: string;
  isMRDisable: boolean;
}) => {
  let color;
  let bgColor;
  let border;

  if (isMeetingRoom && !isMRDisable) {
    color = '#FFFFFF';
    border = '1px solid #3366FF';
  } else if (colorScheme === 'dark') {
    color = '#FFFFFF';
    border = '1px solid #383B40';
  } else {
    color = '#000000';
    border = '1px solid #C4C4C4';
  }

  if (isMRDisable) {
    bgColor = colorScheme === 'dark' ? '#383B40' : '#C4C4C4';
  } else {
    bgColor = colorScheme === 'dark' ? '#2D2F34' : '#DCDCDC';
  }
  return css(
    mq({
      '@media (hover: hover) and (pointer: fine)': {
        '&:hover': {
          backgroundColor: isMeetingRoom && !isMRDisable ? '#3366FF' : 'none',
          color,
        },
      },
      '& p': {
        textAlign: 'center',
        fontWeight: 600,
        fontSize: ['8px', '8px', '10px', '10px'],
        lineHeight: '12px',
      },
      cursor: isMeetingRoom && !isMRDisable ? 'pointer' : 'default',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: ['41.49px', '36.47px', '50px', '50px'],
      height: ['41.49px', '36.47px', '50px', '50px'],
      color: colorScheme === 'dark' ? '#FFFFFF' : '#000000',
      borderRadius: '5px',
      border,
      marginTop: ['4.15px', '3.53px', '5px', '5px'],
      background: bgColor,
    }),
  );
};

const tooltipStyles = css({
  '& .MuiTooltip-tooltip': {
    fontSize: '8px !important',
  },
});

const styles = {
  meetingRoomStyles,
  tooltipStyles,
};

export default styles;
