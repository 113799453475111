/** @jsxImportSource @emotion/react */
import React, { ReactElement, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Dialog, IconButton } from '@mui/material';
import CloseIcon from '../../../../assets/closeIcon';
import { useTelegram } from '../../../../hooks/useTelegram';
import { useAppSelector } from '../../../../hooks/useAppSelector';

import styles from './styles';

interface BookInfoModalProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
}

export function BookInfoModal({ setOpen, open }: BookInfoModalProps): ReactElement {
  const [modalTitle, setModalTitle] = useState('');
  const [modalInfo, setModalInfo] = useState('');

  const { tgUserId, colorScheme } = useTelegram();

  const editedUserSpace = useAppSelector((store) => store.officeSpaceReducer.editedUserSpace);

  const bookedSpace = useAppSelector((store) => store.officeSpaceReducer.bookedSpaces).find(
    (s) => s.spaceUser.telegramUserId === tgUserId && s.userSpaceId !== editedUserSpace?.id,
  );

  const onCloseHandler = () => {
    setOpen(false);
  };

  const startDateString = dayjs(bookedSpace?.startDate).format('DD.MM.YYYY');
  const endDateString = dayjs(bookedSpace?.endDate).format('DD.MM.YYYY');

  useEffect(() => {
    if (bookedSpace?.isRepeatAlways) {
      setModalTitle(`${bookedSpace?.name}`);
      setModalInfo(
        `You have already booked a DESK. You can’t make 2 or more bookings on the same day.`,
      );
    } else if (bookedSpace?.endDate) {
      setModalTitle(`${bookedSpace?.name} - ${startDateString}-${endDateString}`);
      setModalInfo(
        `You have already booked a DESK on ${startDateString}-${endDateString}. You can’t make 2 or more bookings on the same day.`,
      );
    } else {
      setModalTitle(`${bookedSpace?.name} - ${startDateString}`);
      setModalInfo(
        `You have already booked a DESK on ${startDateString}.You can’t make 2 or more bookings on the same day.`,
      );
    }
  }, [bookedSpace?.endDate, bookedSpace?.startDate, bookedSpace?.isRepeatAlways]);

  return (
    <Dialog onClose={() => setOpen(false)} open={open} css={styles.dialogStyles({ colorScheme })}>
      <div css={styles.modalWrapperStyles}>
        <div css={styles.headerWrapperStyles}>
          <div css={styles.closeBtnWrapperStyles}>
            <IconButton css={styles.closeBtnStyles({ colorScheme })} onClick={onCloseHandler}>
              <CloseIcon />
            </IconButton>
          </div>
          <div>
            <h3 css={styles.headerTextStyles}>{modalTitle}</h3>
          </div>
        </div>
        <div>
          <p css={styles.modalInfoTextStyles({ colorScheme })}>{modalInfo}</p>
        </div>
        <div css={styles.btnWrapperStyles}>
          <button css={styles.btnStyles} type="button" onClick={onCloseHandler}>
            OK
          </button>
        </div>
      </div>
    </Dialog>
  );
}
