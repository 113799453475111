/** @jsxImportSource @emotion/react */
import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useDispatch } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { useSearchParams } from 'react-router-dom';
import { setEditedUserSpace, setOfficeSpace } from '../../../store/reducers/officeSpace';

import styles from './styles';

interface CalendarProps {
  setCalendarActive: React.Dispatch<React.SetStateAction<boolean>>;
  calendarValue: Dayjs | null | undefined;
  setCalendarValue: React.Dispatch<React.SetStateAction<Dayjs | null | undefined>>;
  setChosenDateState?: React.Dispatch<React.SetStateAction<string | undefined>>;
  setBookingDateStorageValue?: (value: string) => void;
  setIsEditClosed?: React.Dispatch<React.SetStateAction<boolean>>;
  setEditWorkPlaceActive?: React.Dispatch<React.SetStateAction<boolean>>;
  officeMap?: boolean;
}

function Calendar({
  setCalendarActive,
  calendarValue,
  setCalendarValue,
  setChosenDateState,
  setBookingDateStorageValue,
  setIsEditClosed,
  setEditWorkPlaceActive,
  officeMap,
}: CalendarProps) {
  const dispatch = useDispatch();

  const [queryParams, setQueryParams] = useSearchParams();

  return (
    <div css={styles.datePickerWrapperStyles}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StaticDatePicker
          maxDate={dayjs().add(1, 'year')}
          minDate={dayjs()}
          views={['day']}
          disablePast
          onAccept={() => setCalendarActive(false)}
          css={styles.calendarStyles}
          displayStaticWrapperAs="desktop"
          value={calendarValue}
          onChange={(newValue) => {
            setCalendarValue(newValue);
            if (newValue) {
              const newValueString = newValue.format('YYYY-MM-DD');
              if (setChosenDateState) {
                setChosenDateState(newValueString);
              }

              if (setBookingDateStorageValue) {
                setBookingDateStorageValue(newValueString);
              }

              if (setIsEditClosed) {
                setIsEditClosed(false);
              }

              if (officeMap) {
                dispatch(setEditedUserSpace({ newEditedUserSpace: null }));
                queryParams.set('startDate', newValueString);
                queryParams.delete('endDate');
                queryParams.delete('isClosed');
                queryParams.delete('spaceId');
                queryParams.delete('spaceName');
                setQueryParams(queryParams);
                dispatch(setOfficeSpace({ newSpace: '' }));
              }
            }
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
    </div>
  );
}

export default Calendar;
