/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const wrapperStyles = ({ colorScheme }: { colorScheme: string }) => {
  return css({
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    backgroundColor: colorScheme === 'dark' ? '#1F2023' : '#FFFFFF',
    padding: '50px 16px 20px 16px',
    overflowY: 'scroll',
    height: '100vh',
  });
};

const backIconStyles = css({
  left: '4px',
  color: `var(--tg-theme-text-color)`,
});

const nextIconStyles = css({
  color: `var(--tg-theme-text-color)`,
  position: 'relative',
  left: '1px',
});

const emptyBtn = css({
  height: '40px',
  width: '40px',
});

const textStyles = css({
  color: 'var(--tg-theme-text-color)',
});

const goBackWrapperStyles = css({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  height: '40px',
  width: '40px',
});

const goBackIconStyles = css({
  color: `var(--tg-theme-text-color)`,
  position: 'relative',
  left: '4px',
});

const styles = {
  wrapperStyles,
  backIconStyles,
  nextIconStyles,
  emptyBtn,
  textStyles,
  goBackWrapperStyles,
  goBackIconStyles,
};

export default styles;
