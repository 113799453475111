/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const btnWrapperStyles = css({
  marginTop: '50px',
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'center',
});

const editBtnStyles = css({
  '@media (hover: hover) and (pointer: fine)': {
    '&:hover': {
      backgroundColor: '#3366FF',
      color: '#ffffff',
      opacity: '0.7',
      transition: 'all 0.3s ease-out',
    },
  },
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '5px 15px',
  width: '137px',
  height: '36px',
  backgroundColor: '#3366FF',
  borderRadius: '5px',
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '14px',
  color: '#ffffff',
  textTransform: 'none',
  marginRight: '20px',
  transition: 'all 0.3s ease-out',
});

const cancelBtnStyles = ({ colorScheme }: { colorScheme: string }) => {
  return css({
    '@media (hover: hover) and (pointer: fine)': {
      '&:hover': {
        border: '1px solid #c70000',
        transition: 'all 0.3s ease-out',
        color: '#c70000',
      },
    },
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px 15px',
    width: '137px',
    height: '36px',
    background: colorScheme === 'dark' ? '#27292D' : '#ffffff',
    borderRadius: '5px',
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '14px',
    color: '#3366FF',
    border: '1px solid #3366FF',
    transition: 'all 0.3s ease-out',
  });
};

const styles = {
  btnWrapperStyles,
  editBtnStyles,
  cancelBtnStyles,
};

export default styles;
