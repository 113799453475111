/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const formStyles = ({ colorScheme }: { colorScheme: string }) => {
  return css({
    '& .inputLabel': {
      fontWeight: 600,
      fontSize: '16px',
      fontFamily: `Montserrat !important`,
      fontStyle: 'normal !important',
    },

    '& .MuiFormHelperText-root': {
      fontWeight: 600,
      fontSize: '16px',
      fontFamily: `Montserrat !important`,
      fontStyle: 'normal !important',
    },

    '& .MuiInputBase-root': {
      color: colorScheme === 'dark' ? '#FFFFFF' : '#000000',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '18px',
      height: '50px',
      fontFamily: `Montserrat !important`,
      fontStyle: 'normal !important',
    },

    '& .MuiInputBase-input': {
      display: 'flex',
      alignItems: 'center',
      padding: '0px 14px',
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: colorScheme === 'dark' ? '#383B40' : '#C4C4C4',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '18px',
    },

    '& .MuiFormLabel-root': {
      color: colorScheme === 'dark' ? '#383B40 !important' : '#000000 !important',
    },

    '& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: colorScheme === 'dark' ? '#3366FF !important' : '#3366FF !important',
    },

    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100vh',
    padding: '20px',
    backgroundColor: colorScheme === 'dark' ? '#1F2023' : '#FFFFFF',
  });
};

const headerTextStyles = ({ colorScheme }: { colorScheme: string }) => {
  return css({
    color: colorScheme === 'dark' ? '#FFFFFF' : '#000000',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '24px',
    marginBottom: '20px',
  });
};

const inputWrapperStyles = ({ surnameInput }: { surnameInput?: boolean }) => {
  return css({
    marginTop: surnameInput ? '6px' : '0px',
  });
};

const styles = {
  formStyles,
  headerTextStyles,
  inputWrapperStyles,
};

export default styles;
