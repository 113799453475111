import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { REPEAT_VALUE } from '../../constants';

export interface MeetingRoomUser {
  id: string;
  name: string;
  surname: string;
  telegramUserName: string;
  telegramUserId: number;
  userMeetingRoomInfo: {
    id: string;
    roomId: string;
    spaceName: string;
    date: string;
    startTime: string;
    endTime: string;
    isRepeated: boolean;
    repeatValue: REPEAT_VALUE | null;
    repeatEndDate: string | null;
    isRepeatAlways: boolean;
  };
}

export interface EditedMeetingRoomUser {
  id: string;
  telegramUserId: number;
  userMeetingRoomInfo: {
    id: string;
    roomId: string;
    spaceName: string;
    date: string;
    startTime: string;
    endTime: string;
    isRepeated: boolean;
    repeatValue: REPEAT_VALUE | null;
    repeatEndDate: string | null;
    isRepeatAlways: boolean;
  };
}

interface MeetingRoomsState {
  allMeetingRoomUsers: MeetingRoomUser[];
  editedUserMeetingRoom: EditedMeetingRoomUser | null;
}

interface SetMeetingRoomUsersPayload {
  newMeetingRoomUsers: MeetingRoomUser[];
}

interface SetAllDayBookingPayload {
  newMeetingRoomUser: MeetingRoomUser;
}

interface DeleteAllDayBookingPayload {
  meetingRoomUserId: string;
}

interface SetEditedUserMeetingRoomPayload {
  newEditedUserMeetingRoom: EditedMeetingRoomUser | null;
}

const initialState: MeetingRoomsState = {
  allMeetingRoomUsers: [],
  editedUserMeetingRoom: null,
};

export const meetingRoomsSlice = createSlice({
  name: 'meetingRooms',
  initialState,
  reducers: {
    setAllDayBooking: (
      state,
      { payload: { newMeetingRoomUser } }: PayloadAction<SetAllDayBookingPayload>,
    ) => {
      state.allMeetingRoomUsers.push(newMeetingRoomUser);
    },

    deleteAllDayBooking: (
      state,
      { payload: { meetingRoomUserId } }: PayloadAction<DeleteAllDayBookingPayload>,
    ) => {
      const meetingRoomUser = state.allMeetingRoomUsers.find((mu) => mu.id === meetingRoomUserId);

      if (meetingRoomUser) {
        const meetingRoomUserIndex = state.allMeetingRoomUsers.indexOf(meetingRoomUser);
        state.allMeetingRoomUsers.splice(meetingRoomUserIndex, 1);
      }
    },

    setAllMeetingRoomUsers: (
      state,
      { payload: { newMeetingRoomUsers } }: PayloadAction<SetMeetingRoomUsersPayload>,
    ) => {
      state.allMeetingRoomUsers = newMeetingRoomUsers;
    },

    setEditedUserMeetingRoom: (
      state,
      { payload: { newEditedUserMeetingRoom } }: PayloadAction<SetEditedUserMeetingRoomPayload>,
    ) => {
      state.editedUserMeetingRoom = newEditedUserMeetingRoom;
    },
  },
});

export const {
  reducer: meetingRoomsReducer,
  actions: {
    setAllDayBooking,
    deleteAllDayBooking,
    setAllMeetingRoomUsers,
    setEditedUserMeetingRoom,
  },
} = meetingRoomsSlice;
