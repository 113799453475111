/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useChangeUserMutation } from '../../../api/rootApi';
import { validationSchema } from '../../../utils/validators/registrationForm';
import { useTelegram } from '../../../hooks/useTelegram';
import { Input } from '../../common/input';
import { NAME_MAX_LENGTH, SURNAME_MAX_LENGTH } from '../../../constants';
import { useAppSelector } from '../../../hooks/useAppSelector';

import styles from './styles';

function UserProfile() {
  const { tg, queryId, colorScheme } = useTelegram();

  const userInfo = useAppSelector((store) => store.userReducer.user);

  const formOption = {
    defaultValues: {
      name: userInfo?.name || '',
      surname: userInfo?.surname || '',
    },
    resolver: yupResolver(validationSchema),
  };
  const { handleSubmit, register, formState, getValues, setValue } = useForm(formOption);

  const { dirtyFields, errors } = formState;

  const [editUser] = useChangeUserMutation();

  useEffect(() => {
    if (userInfo) {
      setValue('name', userInfo.name);
      setValue('surname', userInfo.surname);
    }
  }, [userInfo]);

  const nameValue = getValues('name');

  const surnameValue = getValues('surname');

  const onSubmit: SubmitHandler<any> = async (formData): Promise<void> => {
    const { name, surname } = formData;

    if (userInfo && queryId) {
      const data = {
        id: userInfo.id,
        name,
        surname,
        queryId,
      };

      editUser(data);
    }
  };

  useEffect(() => {
    tg.onEvent('mainButtonClicked', handleSubmit(onSubmit));
    return () => {
      tg.offEvent('mainButtonClicked', handleSubmit(onSubmit));
    };
  }, [onSubmit]);

  useEffect(() => {
    tg.MainButton.setParams({
      text: 'Change info',
    });
  }, []);

  useEffect(() => {
    if (dirtyFields.name || dirtyFields.surname) {
      tg.MainButton.show();
    } else {
      tg.MainButton.hide();
    }
  }, [nameValue, surnameValue]);

  return (
    <div css={styles.formStyles({ colorScheme })}>
      <h3 css={styles.headerTextStyles({ colorScheme })}>Change your info</h3>
      <form>
        <div>
          <Input
            label="Name"
            placeholder="Name"
            maxLength={NAME_MAX_LENGTH}
            name="name"
            errorMsg={errors.name?.message}
            variant="outlined"
            register={register}
            InputLabelProps={{ className: 'inputLabel', shrink: true }}
          />
        </div>
        <div css={styles.inputWrapperStyles({ surnameInput: true })}>
          <Input
            label="Surname"
            placeholder="Surname"
            maxLength={SURNAME_MAX_LENGTH}
            name="surname"
            variant="outlined"
            errorMsg={errors.surname?.message}
            register={register}
            InputLabelProps={{
              className: 'inputLabel',
              shrink: true,
            }}
          />
        </div>
      </form>
    </div>
  );
}

export default UserProfile;
