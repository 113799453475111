/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const dialogStyles = ({ colorScheme }: { colorScheme: string }) => {
  return css({
    '& .MuiPaper-root': {
      borderRadius: '10px',
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.08)',
      background: colorScheme === 'dark' ? '#27292D' : '#FFFFFF',
    },
  });
};

const modalWrapperStyles = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '28px',
});

const closeBtnStyles = ({ colorScheme }: { colorScheme: string }) => {
  return css({
    position: 'relative',
    left: '5px',
    bottom: '10px',
    height: '25px',
    width: '25px',
    padding: '2px',
    color: colorScheme === 'dark' ? '#FFFFFF' : '#000000',
  });
};

const btnWrapperStyles = css({
  marginTop: '25px',
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'center',
});

const headerWrapperStyles = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

const headerTextStyles = css({
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '24px',
  color: '#3366FF',
  marginBottom: '15px',
});

const modalInfoTextStyles = ({ colorScheme }: { colorScheme: string }) => {
  return css({
    minWidth: '255px',
    fontWeight: 600,
    maxWidth: '290px',
    fontSize: '12px',
    lineHeight: '14px',
    color: colorScheme === 'dark' ? '#FFFFFF' : '#000000',
  });
};

const confirmBtnStyles = css({
  '@media (hover: hover) and (pointer: fine)': {
    '&:hover': {
      opacity: '0.7',
      transition: 'all 0.3s ease-out',
    },
  },
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '5px 15px',
  width: '137px',
  height: '36px',
  background: '#3366FF',
  borderRadius: '5px',
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '14px',
  color: '#ffffff',
  marginRight: '20px',
  transition: 'all 0.3s ease-out',
});

const cancelBtnStyles = ({ colorScheme }: { colorScheme: string }) => {
  return css({
    '@media (hover: hover) and (pointer: fine)': {
      '&:hover': {
        border: '1px solid #c70000',
        transition: 'all 0.3s ease-out',
        color: '#c70000',
      },
    },
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px 15px',
    width: '137px',
    height: '36px',
    background: colorScheme === 'dark' ? '#27292D' : '#ffffff',
    borderRadius: '5px',
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '14px',
    color: '#3366FF',
    border: '1px solid #3366FF',
    transition: 'all 0.3s ease-out',
  });
};

const closeBtnWrapperStyles = css({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  height: '15px',
});

const styles = {
  modalWrapperStyles,
  closeBtnStyles,
  btnWrapperStyles,
  headerWrapperStyles,
  dialogStyles,
  headerTextStyles,
  modalInfoTextStyles,
  confirmBtnStyles,
  cancelBtnStyles,
  closeBtnWrapperStyles,
};

export default styles;
