/** @jsxImportSource @emotion/react */
import React, { ReactElement } from 'react';
import { Dialog, FormControlLabel, IconButton, Radio, RadioGroup } from '@mui/material';
import CloseIcon from '../../../../assets/closeIcon';
import { useTelegram } from '../../../../hooks/useTelegram';

import styles from './styles';

export enum EDIT_RADIO_VALUES {
  ALL = 'all',
  CURRENT = 'current',
}

interface EditRepeatBookingModalProps {
  open: boolean;
  onClose: () => void;
  onEditBookingHandler: () => void;
  editRadioValue: EDIT_RADIO_VALUES;
  setEditRadioValue: (value: EDIT_RADIO_VALUES) => void;
}

export function EditRepeatBookingModal({
  open,
  onClose,
  onEditBookingHandler,
  editRadioValue,
  setEditRadioValue,
}: EditRepeatBookingModalProps): ReactElement {
  const { colorScheme } = useTelegram();

  const onRemoveRadioValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditRadioValue((event.target as HTMLInputElement).value as EDIT_RADIO_VALUES);
  };

  return (
    <Dialog onClose={onClose} open={open} css={styles.dialogStyles({ colorScheme })}>
      <div css={styles.modalWrapperStyles}>
        <div css={styles.headerWrapperStyles}>
          <div css={styles.closeBtnWrapperStyles}>
            <IconButton css={styles.closeBtnStyles({ colorScheme })} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div css={styles.contentWrapperStyles}>
          <RadioGroup value={editRadioValue} onChange={onRemoveRadioValueChange}>
            <FormControlLabel
              value={EDIT_RADIO_VALUES.CURRENT}
              control={<Radio />}
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontWeight: 600,
                  fontSize: '10px',
                  color: colorScheme === 'dark' ? '#FFFFFF' : '#000000',
                },
              }}
              label="This booking"
            />
            <FormControlLabel
              value={EDIT_RADIO_VALUES.ALL}
              control={<Radio />}
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontWeight: 600,
                  fontSize: '10px',
                  color: colorScheme === 'dark' ? '#FFFFFF' : '#000000',
                },
              }}
              label="All bookings"
            />
          </RadioGroup>
        </div>
        <div css={styles.btnWrapperStyles}>
          <div css={styles.repeatedBtnWrapperStyles}>
            <button type="button" css={styles.saveButtonStyles} onClick={onEditBookingHandler}>
              Save
            </button>
            <button type="button" css={styles.cancelBtnStyles({ colorScheme })} onClick={onClose}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
