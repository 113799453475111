/** @jsxImportSource @emotion/react */
// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuidv4 } from 'uuid';
import React, { useCallback, useEffect, useState } from 'react';
import { Grid, CircularProgress } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
  useBookMeetingRoomMutation,
  useLazyGetAllMeetingRoomsUsersQuery,
} from '../../../api/rootApi';
import { MEETING_ROOMS_TIMES } from '../../../constants';
import { useAppSelector } from '../../../hooks/useAppSelector';
import TimeRow from '../../common/timeRow';
import { useTelegram } from '../../../hooks/useTelegram';
import { ConfirmModal } from '../bookMeetingRoomLayout/confirmModal';
import { setAllDayBooking, setAllMeetingRoomUsers } from '../../../store/reducers/meetingRooms';
import MeetingRoomTimetableHeader from './meetingRoomTimetableHeader';
import TableHeader from '../../common/tableHeader';
import BookedTimeCell from '../../common/bookedTimeCell';
import EmptyRow from '../../common/timeRow/emptyRow';
import { findEnableTimesIntervals } from '../../../utils/findEnableTimeIntervals';

import styles from './styles';

const isBetween = require('dayjs/plugin/isBetween');

dayjs.extend(isBetween);

export const FAKE_ID = '1';

function MeetingRoomTimetable() {
  const [calendarActive, setCalendarActive] = useState(false);
  const [calendarValue, setCalendarValue] = useState<Dayjs | null | undefined>();
  const [checked, setChecked] = useState(false);
  const [modalActive, setModalActive] = useState(false);
  const [startTimeValue, setStartTimeValue] = useState<Dayjs | null | undefined>();
  const [endTimeValue, setEndTimeValue] = useState<Dayjs | null | undefined>();
  const [dateState, setDateState] = useState<string | undefined>(undefined);
  const [meetingRoomIdState, setMeetingRoomIdState] = useState<string | undefined>(undefined);

  const { tg, user, queryId, tgUserId, colorScheme } = useTelegram();

  const [queryParams] = useSearchParams();

  const [bookMeetingRoom] = useBookMeetingRoomMutation();

  const chosenDate = queryParams.get('date') ?? undefined;

  const meetingRoomId = queryParams.get('meetingRoomId') ?? undefined;

  const meetingRoomName = queryParams.get('meetingRoomName') ?? undefined;

  const isMyBookings = Boolean(queryParams.get('isMyBookings'));

  const userInfo = useAppSelector((store) => store.userReducer.user);

  const dispatch = useDispatch();

  useEffect(() => {
    if (chosenDate && meetingRoomId && meetingRoomName) {
      setCalendarValue(dayjs(chosenDate));
      setDateState(chosenDate);
      setMeetingRoomIdState(meetingRoomId);
    }
  }, []);

  const [getAllMeetingRoomUsers, { isFetching: isFetchingGetAllMeetingRoomUsers }] =
    useLazyGetAllMeetingRoomsUsersQuery();

  useEffect(() => {
    if (dateState && meetingRoomIdState) {
      dispatch(setAllMeetingRoomUsers({ newMeetingRoomUsers: [] }));
      getAllMeetingRoomUsers({ date: dateState });
    }
  }, [dateState, meetingRoomIdState]);

  const userEmail = useAppSelector((store) => store.userReducer.user.email);

  const onSendData = useCallback(() => {
    if (
      queryId &&
      startTimeValue &&
      endTimeValue &&
      meetingRoomName &&
      meetingRoomId &&
      tgUserId &&
      dateState &&
      userEmail
    ) {
      const startTimeString = startTimeValue.format('HH:mm:ss');
      const endTimeString = endTimeValue.format('HH:mm:ss');

      const data = {
        queryId,
        meetingRoomId,
        telegramUserId: tgUserId,
        date: dateState,
        startTime: startTimeString,
        endTime: endTimeString,
        spaceName: meetingRoomName,
        allDay: checked,
        userEmail,
      };

      bookMeetingRoom(data);
    }
  }, [startTimeValue, endTimeValue]);

  useEffect(() => {
    tg.onEvent('mainButtonClicked', onSendData);
    return () => {
      tg.offEvent('mainButtonClicked', onSendData);
    };
  }, [onSendData]);

  useEffect(() => {
    tg.MainButton.setParams({
      text: 'Confirm',
    });
  }, []);

  useEffect(() => {
    if (!startTimeValue || !endTimeValue || modalActive) {
      tg.MainButton.hide();
    } else {
      tg.MainButton.show();
    }
  }, [startTimeValue, endTimeValue, modalActive]);

  const meetingRoomUsers = useAppSelector(
    (store) => store.meetingRoomsReducer.allMeetingRoomUsers,
  ).filter((b) => {
    const roomNumber = meetingRoomName?.split(' ')[2];
    if (b.userMeetingRoomInfo.spaceName === `MR${roomNumber}`) {
      return true;
    }
    return false;
  });

  const times = MEETING_ROOMS_TIMES.map((t) => {
    return <TimeRow key={t} time={t} />;
  });

  const isAuthorHaveBookings = meetingRoomUsers.some((m) => m.telegramUserId === tgUserId);

  const onCancel = () => {
    setModalActive(false);
    setChecked(false);
  };

  const onConfirm = () => {
    const minTime = dayjs(`${dateState} 07:00:00`);
    const maxTime = dayjs(`${dateState} 23:00:00`);
    setStartTimeValue(minTime);
    setEndTimeValue(maxTime);

    if (userInfo && tgUserId && dateState && meetingRoomId && meetingRoomName) {
      dispatch(
        dispatch(
          setAllDayBooking({
            newMeetingRoomUser: {
              id: FAKE_ID,
              name: userInfo.name,
              surname: userInfo.surname,
              telegramUserName: user,
              telegramUserId: tgUserId,
              userMeetingRoomInfo: {
                id: userInfo.id,
                spaceName: `MR${meetingRoomName.split(' ')[2]}`,
                roomId: meetingRoomId,
                date: dateState,
                endTime: '23:00:00',
                startTime: '07:00:00',
                isRepeated: false,
                repeatValue: null,
                repeatEndDate: null,
                isRepeatAlways: false,
              },
            },
          }),
        ),
      );
    }

    setModalActive(false);
  };

  const bookedTimeCell = meetingRoomUsers.map((u) => {
    return (
      <BookedTimeCell
        meetingRoomUser={u}
        key={uuidv4()}
        meetingRoomName={u.userMeetingRoomInfo.spaceName}
        chosenDateState={dateState}
      />
    );
  });

  const enableTimeRows = findEnableTimesIntervals(meetingRoomUsers, dateState);

  const isTimeRowClickable = !calendarActive;

  const enableRows = enableTimeRows.map((i) => {
    return (
      <EmptyRow
        key={Number(new Date(`${i.startTime}`))}
        enableStartTime={dayjs(`${i.startTime}`).format('HH:mm:ss')}
        enableEndTime={dayjs(`${i.endTime}`).format('HH:mm:ss')}
        meetingRoomDateValue={dateState}
        isTimeRowClickable={isTimeRowClickable}
        meetingRoomName={meetingRoomName}
      />
    );
  });

  return (
    <div css={styles.wrapperStyles({ colorScheme })}>
      {isFetchingGetAllMeetingRoomUsers ? (
        <Grid item padding={7} display="flex" justifyContent="center" xs={12}>
          <CircularProgress />
        </Grid>
      ) : (
        <div>
          <MeetingRoomTimetableHeader
            isMyBookings={isMyBookings}
            setChecked={setChecked}
            checked={checked}
            isAuthorHaveBookings={isAuthorHaveBookings}
            setModalActive={setModalActive}
            setStartTimeValue={setStartTimeValue}
            setEndTimeValue={setEndTimeValue}
            userInfo={userInfo}
            dateState={dateState}
            meetingRoomId={meetingRoomId}
            meetingRoomName={meetingRoomName}
          />
          <Grid>
            <TableHeader
              dateState={dateState}
              setDateState={setDateState}
              setChecked={setChecked}
              setCalendarActive={setCalendarActive}
              calendarActive={calendarActive}
              calendarValue={calendarValue}
              setCalendarValue={setCalendarValue}
              meetingRoomName={meetingRoomName}
            />
            <Grid container position="relative">
              {times}
              {enableRows}
              {meetingRoomUsers.length > 0 && bookedTimeCell}
            </Grid>
          </Grid>
        </div>
      )}
      <ConfirmModal
        onCancel={onCancel}
        open={modalActive}
        setOpen={setModalActive}
        onConfirm={onConfirm}
      />
    </div>
  );
}

export default MeetingRoomTimetable;
