/** @jsxImportSource @emotion/react */
// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuidv4 } from 'uuid';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Grid, CircularProgress } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { useLazyGetAllMeetingRoomsUsersQuery } from '../../../api/rootApi';
import { MEETING_ROOMS_TIMES } from '../../../constants';
import { useTelegram } from '../../../hooks/useTelegram';
import TimeRow from '../../common/timeRow';
import BookedTimeCell from '../../common/bookedTimeCell';
import TableHeader from '../../common/tableHeader';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { findEnableTimesIntervals } from '../../../utils/findEnableTimeIntervals';
import EmptyRow from '../../common/timeRow/emptyRow';

import styles from './styles';

const isBetween = require('dayjs/plugin/isBetween');

dayjs.extend(isBetween);

export const FAKE_ID = '1';

function AllMeetingRoomsSchedule() {
  const [dateState, setDateState] = useState<string | undefined>();
  const [calendarActive, setCalendarActive] = useState(false);
  const [calendarValue, setCalendarValue] = useState<Dayjs | null | undefined>();

  const [queryParams] = useSearchParams();

  const { tg } = useTelegram();

  const chosenDate = queryParams.get('date') ?? undefined;

  const { colorScheme } = useTelegram();

  const [getAllMeetingRoomUsers, { isFetching: isFetchingGetAllMeetingRoomUsers }] =
    useLazyGetAllMeetingRoomsUsersQuery();

  useEffect(() => {
    if (dateState) {
      getAllMeetingRoomUsers({ date: dateState });
    }
  }, [dateState]);

  useEffect(() => {
    tg.MainButton.hide();
    if (chosenDate) {
      setDateState(chosenDate);
    } else {
      setDateState(dayjs().format('YYYY-MM-DD'));
    }
  }, []);

  const times = MEETING_ROOMS_TIMES.map((t) => {
    return <TimeRow key={t} time={t} allMeetingRooms />;
  });

  const allMeetingRoomUsers = useAppSelector(
    (store) => store.meetingRoomsReducer.allMeetingRoomUsers,
  );

  const allBookedTimeCell = allMeetingRoomUsers.map((u) => {
    return (
      <BookedTimeCell
        meetingRoomUser={u}
        key={uuidv4()}
        allMeetingRooms
        meetingRoomName={u.userMeetingRoomInfo.spaceName}
        chosenDateState={dateState}
      />
    );
  });

  const meetingRoom1Bookings = allMeetingRoomUsers.filter(
    (b) => b.userMeetingRoomInfo.spaceName === 'MR1',
  );

  const meetingRoom2Bookings = allMeetingRoomUsers.filter(
    (b) => b.userMeetingRoomInfo.spaceName === 'MR2',
  );

  const meetingRoom3Bookings = allMeetingRoomUsers.filter(
    (b) => b.userMeetingRoomInfo.spaceName === 'MR3',
  );

  const enableTimeRows1 = findEnableTimesIntervals(meetingRoom1Bookings, dateState);

  const enableTimeRows2 = findEnableTimesIntervals(meetingRoom2Bookings, dateState);

  const enableTimeRows3 = findEnableTimesIntervals(meetingRoom3Bookings, dateState);

  const isTimeRowClickable = !calendarActive;

  const enableRowsMR1 = enableTimeRows1.map((i) => {
    return (
      <EmptyRow
        key={Number(new Date(`${i.startTime}`))}
        enableStartTime={dayjs(`${i.startTime}`).format('HH:mm:ss')}
        enableEndTime={dayjs(`${i.endTime}`).format('HH:mm:ss')}
        meetingRoomDateValue={dateState}
        isTimeRowClickable={isTimeRowClickable}
        meetingRoomName="MR1"
        allMeetingRooms
      />
    );
  });

  const enableRowsMR2 = enableTimeRows2.map((i) => {
    return (
      <EmptyRow
        key={Number(new Date(`${i.startTime}`))}
        enableStartTime={dayjs(`${i.startTime}`).format('HH:mm:ss')}
        enableEndTime={dayjs(`${i.endTime}`).format('HH:mm:ss')}
        meetingRoomDateValue={dateState}
        isTimeRowClickable={isTimeRowClickable}
        meetingRoomName="MR2"
        allMeetingRooms
      />
    );
  });

  const enableRowsMR3 = enableTimeRows3.map((i) => {
    return (
      <EmptyRow
        key={Number(new Date(`${i.startTime}`))}
        enableStartTime={dayjs(`${i.startTime}`).format('HH:mm:ss')}
        enableEndTime={dayjs(`${i.endTime}`).format('HH:mm:ss')}
        meetingRoomDateValue={dateState}
        isTimeRowClickable={isTimeRowClickable}
        meetingRoomName="MR3"
        allMeetingRooms
      />
    );
  });

  return isFetchingGetAllMeetingRoomUsers ? (
    <Grid item padding={7} display="flex" justifyContent="center" xs={12}>
      <CircularProgress />
    </Grid>
  ) : (
    <div css={styles.wrapperStyles({ colorScheme })}>
      <div>
        <Grid>
          <TableHeader
            allMeetingRooms
            dateState={dateState}
            setDateState={setDateState}
            setCalendarActive={setCalendarActive}
            calendarActive={calendarActive}
            calendarValue={calendarValue}
            setCalendarValue={setCalendarValue}
          />
          <Grid container position="relative">
            {times}
            {allMeetingRoomUsers.length > 0 && allBookedTimeCell}
            {enableRowsMR1}
            {enableRowsMR2}
            {enableRowsMR3}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default AllMeetingRoomsSchedule;
