/** @jsxImportSource @emotion/react */
import React, { ChangeEvent, useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Tooltip } from '@mui/material';
import { useDispatch } from 'react-redux';
import BackArrowIcon from '../../../../assets/backArrowIcon';
import { APP_ROUTES } from '../../../../constants';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { useEnhancedNavigate } from '../../../../hooks/useEnhancedNavigate';
import { useTelegram } from '../../../../hooks/useTelegram';
import { BookInfoModal } from '../../bookMeetingRoomLayout/bookInfoModal';
import {
  deleteAllDayBooking,
  MeetingRoomUser,
  setAllDayBooking,
} from '../../../../store/reducers/meetingRooms';
import { FAKE_ID } from '..';
import { User } from '../../../../store/reducers/user';

import styles from './styles';

const isBetween = require('dayjs/plugin/isBetween');

dayjs.extend(isBetween);

interface MeetingRoomTimetableHeaderProps {
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
  checked: boolean;
  isAuthorHaveBookings: boolean;
  setModalActive: React.Dispatch<React.SetStateAction<boolean>>;
  setStartTimeValue: React.Dispatch<React.SetStateAction<Dayjs | null | undefined>>;
  setEndTimeValue: React.Dispatch<React.SetStateAction<Dayjs | null | undefined>>;
  userInfo: User | null;
  dateState?: string;
  meetingRoomId?: string;
  meetingRoomName?: string;
  isMyBookings: boolean;
}

function MeetingRoomTimetableHeader({
  setChecked,
  checked,
  isAuthorHaveBookings,
  setModalActive,
  setStartTimeValue,
  setEndTimeValue,
  userInfo,
  dateState,
  meetingRoomId,
  meetingRoomName,
  isMyBookings,
}: MeetingRoomTimetableHeaderProps) {
  const { colorScheme, tgUserId, user } = useTelegram();
  const [userHaveBookingsModalActive, setUserHaveBookingsModalActive] = useState(false);
  const [bookedMeetingRoom, setBookedMeetingRoom] = useState<MeetingRoomUser | null>(null);

  const dispatch = useDispatch();

  const allMeetingRoomUsers = useAppSelector(
    (store) => store.meetingRoomsReducer.allMeetingRoomUsers,
  );

  useEffect(() => {
    const minTime = dayjs(`${dateState} 07:00:00`);
    const maxTime = dayjs(`${dateState} 23:00:00`);

    const bookedOtherMeetingRoom = allMeetingRoomUsers.find((u) => {
      const bookedStartTimeDate = dayjs(`${dateState} ${u.userMeetingRoomInfo.startTime}`);
      const bookedEndTimeDate = dayjs(`${dateState} ${u.userMeetingRoomInfo.endTime}`);

      const isStartTimeMinTime =
        minTime.get('hour') === bookedStartTimeDate.get('hour') &&
        minTime.get('minutes') === bookedStartTimeDate.get('minutes');

      const isEndTimeMaxTime =
        maxTime.get('hour') === bookedEndTimeDate.get('hour') &&
        maxTime.get('minutes') === bookedEndTimeDate.get('minutes');

      // @ts-ignore
      const isStartTimeBetween = bookedStartTimeDate.isBetween(minTime, maxTime, '[]');

      // @ts-ignore
      const isEndTimeBetween = bookedEndTimeDate.isBetween(minTime, maxTime, '[]');

      if (
        u.userMeetingRoomInfo.roomId !== meetingRoomId &&
        u.telegramUserId === tgUserId &&
        ((isStartTimeMinTime && isEndTimeMaxTime) || isStartTimeBetween || isEndTimeBetween)
      ) {
        return u;
      }
      return undefined;
    });

    if (bookedOtherMeetingRoom) {
      setBookedMeetingRoom(bookedOtherMeetingRoom);
    } else {
      setBookedMeetingRoom(null);
    }
  }, [dateState, allMeetingRoomUsers]);

  useEffect(() => {}, [checked]);

  const { scrollNavigate } = useEnhancedNavigate();

  const meetingRoomUsers = useAppSelector(
    (store) => store.meetingRoomsReducer.allMeetingRoomUsers,
  ).filter((b) => {
    const roomNumber = meetingRoomName?.split(' ')[2];
    if (b.userMeetingRoomInfo.spaceName === `MR${roomNumber}`) {
      return true;
    }
    return false;
  });

  const isOtherUsersHaveBooking = meetingRoomUsers.some((m) => m.telegramUserId !== tgUserId);

  const handleAllDayCheckBoxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const minTime = dayjs(`${dateState} 07:00:00`);
    const maxTime = dayjs(`${dateState} 23:00:00`);

    if (e.currentTarget.checked) {
      setChecked(true);
      const bookedOtherMeetingRoom = allMeetingRoomUsers.find((u) => {
        const bookedStartTimeDate = dayjs(`${dateState} ${u.userMeetingRoomInfo.startTime}`);
        const bookedEndTimeDate = dayjs(`${dateState} ${u.userMeetingRoomInfo.endTime}`);

        const isStartTimeMinTime =
          minTime.get('hour') === bookedStartTimeDate.get('hour') &&
          minTime.get('minutes') === bookedStartTimeDate.get('minutes');

        const isEndTimeMaxTime =
          maxTime.get('hour') === bookedEndTimeDate.get('hour') &&
          maxTime.get('minutes') === bookedEndTimeDate.get('minutes');

        // @ts-ignore
        const isStartTimeBetween = bookedStartTimeDate.isBetween(minTime, maxTime, '[]');

        // @ts-ignore
        const isEndTimeBetween = bookedEndTimeDate.isBetween(minTime, maxTime, '[]');

        if (
          u.userMeetingRoomInfo.roomId !== meetingRoomId &&
          u.telegramUserId === tgUserId &&
          ((isStartTimeMinTime && isEndTimeMaxTime) || isStartTimeBetween || isEndTimeBetween)
        ) {
          return u;
        }
        return undefined;
      });

      if (bookedOtherMeetingRoom) {
        setBookedMeetingRoom(bookedOtherMeetingRoom);
      } else {
        setBookedMeetingRoom(null);
        if (isAuthorHaveBookings) {
          setModalActive(true);
          setStartTimeValue(minTime);
          setEndTimeValue(maxTime);
        } else {
          setStartTimeValue(minTime);
          setEndTimeValue(maxTime);
          if (userInfo && tgUserId && dateState && meetingRoomName && meetingRoomId) {
            dispatch(
              setAllDayBooking({
                newMeetingRoomUser: {
                  id: FAKE_ID,
                  name: userInfo.name,
                  surname: userInfo.surname,
                  telegramUserName: user,
                  telegramUserId: tgUserId,
                  userMeetingRoomInfo: {
                    id: userInfo.id,
                    spaceName: `MR${meetingRoomName.split(' ')[2]}`,
                    roomId: meetingRoomId,
                    date: dateState,
                    endTime: '23:00:00',
                    startTime: '07:00:00',
                    isRepeated: false,
                    repeatValue: null,
                    repeatEndDate: null,
                    isRepeatAlways: false,
                  },
                },
              }),
            );
          }
        }
      }
    } else {
      setChecked(false);
      setStartTimeValue(null);
      setEndTimeValue(null);

      dispatch(
        deleteAllDayBooking({
          meetingRoomUserId: FAKE_ID,
        }),
      );
    }
  };

  const userClickedAllDayBooking = meetingRoomUsers.find((m) => {
    if (
      m.telegramUserId === tgUserId &&
      m.userMeetingRoomInfo.startTime === '07:00:00' &&
      m.userMeetingRoomInfo.endTime === '23:00:00' &&
      m.id === FAKE_ID
    ) {
      return true;
    }
    return false;
  });

  const userHaveAllDayBooking = meetingRoomUsers.find((m) => {
    if (
      m.telegramUserId === tgUserId &&
      m.userMeetingRoomInfo.startTime === '07:00:00' &&
      m.userMeetingRoomInfo.endTime === '23:00:00' &&
      m.id !== FAKE_ID
    ) {
      return true;
    }
    return false;
  });

  const showCheckBox =
    (!isOtherUsersHaveBooking && !userHaveAllDayBooking) || userClickedAllDayBooking;

  const goBackToMapHandler = () => {
    if (!isMyBookings) {
      scrollNavigate({
        top: 0,
        left: 0,
        path: `${APP_ROUTES.HOME_PAGE}?meetingRoomDate=${dateState}&isMeetingRoom=${true}`,
        replace: true,
        behavior: 'smooth',
      });
    } else {
      scrollNavigate({
        top: 0,
        left: 0,
        path: `${APP_ROUTES.MY_BOOKINGS_ROUTE}`,
        replace: true,
        behavior: 'smooth',
      });
    }
  };

  const chosenDateString = dayjs(dateState).format('DD.MM.YYYY');

  const repeatedBooking = meetingRoomUsers.find((b) => b.userMeetingRoomInfo.isRepeated === true);

  const isAllDayDisabled = !!bookedMeetingRoom || !!repeatedBooking;

  let disableTooltipTitle = '';

  if (bookedMeetingRoom) {
    disableTooltipTitle = `You can’t book this meeting room for all day, because you already have a booking for this day for ${bookedMeetingRoom.userMeetingRoomInfo.spaceName}`;
  }

  if (repeatedBooking) {
    disableTooltipTitle = `You can’t book this meeting room for all day, because you already have a recurring booking`;
  }

  return (
    <div css={styles.headerWrapperStyles}>
      <Tooltip
        title={isMyBookings ? 'Back to my bookings' : 'Back to the office scheme'}
        placement="top"
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: colorScheme === 'dark' ? '#383B40' : '#ffffff',
              color: colorScheme === 'dark' ? '#ffffff' : '#000000',
              boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
              fontWeight: 400,
              fontSize: '8px',
              bottom: '10px',
              left: '40px',
              position: 'relative',
            },
          },
          popper: {
            sx: {
              height: '10px',
            },
          },
        }}>
        <button
          type="button"
          css={styles.goBackBtnStyles({ colorScheme })}
          onClick={goBackToMapHandler}>
          <BackArrowIcon />
        </button>
      </Tooltip>

      {showCheckBox && (
        <Tooltip
          title={disableTooltipTitle}
          enterTouchDelay={0}
          leaveTouchDelay={5000}
          placement="top"
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: colorScheme === 'dark' ? '#383B40' : '#ffffff',
                color: colorScheme === 'dark' ? '#ffffff' : '#000000',
                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                fontWeight: 400,
                fontSize: '8px',
                bottom: '10px',
                right: '45px',
                position: 'relative',
              },
            },
            popper: {
              sx: {
                height: '10px',
              },
            },
          }}>
          <div css={styles.checkBoxWrapperStyles({ isDisable: isAllDayDisabled })}>
            <label htmlFor="allDayCheckbox" css={styles.checkboxLabelStyles({ colorScheme })}>
              <span
                css={styles.checkboxTextStyles({
                  colorScheme,
                  checked,
                  isDisable: isAllDayDisabled,
                })}
                className="checkBoxText">
                All day
              </span>
              <input
                id="allDayCheckbox"
                className="checkBox"
                disabled={isAllDayDisabled}
                css={styles.checkboxStyles({
                  checked,
                  colorScheme,
                  isDisable: isAllDayDisabled,
                })}
                type="checkbox"
                checked={checked}
                onChange={handleAllDayCheckBoxChange}
              />
            </label>
          </div>
        </Tooltip>
      )}

      {userHaveBookingsModalActive && bookedMeetingRoom && (
        <BookInfoModal
          setOpen={setUserHaveBookingsModalActive}
          open={userHaveBookingsModalActive}
          meetingRoomName={meetingRoomName!}
          bookedMeetingRoomName={bookedMeetingRoom.userMeetingRoomInfo.spaceName}
          chosenDate={chosenDateString}
          setChecked={setChecked}
        />
      )}
    </div>
  );
}

export default MeetingRoomTimetableHeader;
