/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import facepaint from 'facepaint';
import { BREAKPOINTS } from '../../../../constants';

const mq = facepaint(BREAKPOINTS.map((bp) => `@media (min-width: ${bp}px)`));

const officeMapWrapperStyles = ({ colorScheme }: { colorScheme: string }) => {
  return css(
    mq({
      '::-webkit-scrollbar': {
        display: 'none',
      },
      display: 'flex',
      padding: '10px',
      maxWidth: ['505px', '480px', '570px', '570px'],
      maxHeight: ['487px', '436px', '575px', '575px'],
      flexDirection: 'column',
      cursor: 'auto',
      overflow: 'scroll',
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
      backgroundColor: colorScheme === 'dark' ? '#27292D' : '#E8E8E8',
      marginBottom: '20px',
    }),
  );
};

const windowWrapperStyles = css(
  mq({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: ['483px', '460px', '550px', '550px'],
  }),
);

const windowStyles = ({ colorScheme }: { colorScheme: string }) => {
  return css(
    mq({
      marginTop: '10px',
      display: 'flex',
      flexDirection: 'row',
      height: ['25px', '22px', '30px', '30px'],
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      backgroundColor: colorScheme === 'dark' ? '#2D2F34' : '#DCDCDC',
      padding: '11px 0px',
      fontWeight: 600,
      fontSize: ['8px', '8px', '10px', '10px'],
      lineHeight: '12px',
      textTransform: 'uppercase',
      color: colorScheme === 'dark' ? '#FFFFFF' : '#000000',
    }),
  );
};

const styles = {
  officeMapWrapperStyles,
  windowWrapperStyles,
  windowStyles,
};

export default styles;
