/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/** @jsxImportSource @emotion/react */
import React from 'react';
import { Tooltip } from '@mui/material';
import dayjs from 'dayjs';
import { APP_ROUTES, MEETING_ROOMS } from '../../../../constants';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { useEnhancedNavigate } from '../../../../hooks/useEnhancedNavigate';
import { useTelegram } from '../../../../hooks/useTelegram';
import { getMeetingRoomTimeDiff } from '../../../../utils/getMeetingRoomTimeDiff';

import styles from './styles';

interface MeetingRoomProps {
  chosenMeetingRoomDate?: string;
  isMeetingRoom: boolean;
  roomName: string;
}

function MeetingRoom({ chosenMeetingRoomDate, isMeetingRoom, roomName }: MeetingRoomProps) {
  const { scrollNavigate } = useEnhancedNavigate();

  const { colorScheme } = useTelegram();

  const omMeetingRoomClickHandler = (name: string) => {
    const meetingRoomId = MEETING_ROOMS.find((r) => name === r.name)?.id;

    scrollNavigate({
      top: 0,
      left: 0,
      path: `${APP_ROUTES.MEETING_ROOM_TIMETABLE_PAGE}?date=${chosenMeetingRoomDate}&meetingRoomId=${meetingRoomId}&meetingRoomName=${name}`,
      replace: true,
      behavior: 'smooth',
    });
  };

  const allMeetingRoomUsers = useAppSelector(
    (store) => store.meetingRoomsReducer.allMeetingRoomUsers,
  );

  const maxMeetingRoomTime = dayjs(`${chosenMeetingRoomDate} 23:00:00`);
  const minMeetingRoomTime = dayjs(`${chosenMeetingRoomDate} 07:00:00`);

  const maxTimeDiff = maxMeetingRoomTime.diff(minMeetingRoomTime);

  const mRTimesDiff = getMeetingRoomTimeDiff(roomName, allMeetingRoomUsers);

  const isMRDisable = maxTimeDiff === mRTimesDiff;

  const mrNumber = roomName.split('')[2];

  return (
    <Tooltip
      enterTouchDelay={0}
      title={isMRDisable && isMeetingRoom ? 'This meeting room is unavailable on this day' : ''}
      placement="bottom-end"
      leaveTouchDelay={3000}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: colorScheme === 'dark' ? '#383B40' : '#ffffff',
            color: colorScheme === 'dark' ? '#ffffff' : '#000000',
            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
            fontWeight: 400,
            fontSize: '8px',
            marginTop: '2px !important',
          },
        },
      }}>
      {isMeetingRoom && !isMRDisable ? (
        <div
          css={styles.meetingRoomStyles({ isMeetingRoom, colorScheme, isMRDisable })}
          onClick={() => omMeetingRoomClickHandler(`Meeting Room ${mrNumber}`)}>
          <p>{roomName}</p>
        </div>
      ) : (
        <div css={styles.meetingRoomStyles({ isMeetingRoom, colorScheme, isMRDisable })}>
          <p>{roomName}</p>
        </div>
      )}
    </Tooltip>
  );
}

export default MeetingRoom;
