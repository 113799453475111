import { makeStyles } from '@material-ui/styles';

export const useStyles = ({ colorScheme }: { colorScheme: string }) => {
  return makeStyles({
    root: {
      '& label.Mui-focused': {
        color: '#3366FF !important',
      },

      '& label.Mui-error': {
        color: '#EF3340 !important',
      },

      '& label': {
        color: colorScheme === 'dark' ? '#383B40 !important' : '#000000 !important',
      },

      '& .MuiMenu-paper': {
        width: '94px !important',
        minWidth: '94px !important',
        height: '308px',
        left: '28px !important',
        backgroundColor: colorScheme === 'dark' ? '#27292D !important' : '#FFFFFF !important',
        color: colorScheme === 'dark' ? '#FFFFFF !important' : '#000000 !important',
      },

      '& .MuiList-root': {
        padding: '5px 6px !important',
      },

      '& .Mui-selected': {
        backgroundColor: '#3366FF !important',
        color: '#FFFFFF',
      },

      '& .repeatMenu': {
        width: '80px !important',
        minWidth: '80px !important',
        height: '76px',
        left: '125px !important',
      },
    },
  })();
};
