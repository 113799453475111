/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, CircularProgress, AppBar } from '@mui/material';
import BookingsAccordion from './bookingsAccordion';
import { useTelegram } from '../../../hooks/useTelegram';
import MyBookingsTabs from './myBookingsTabs';
import {
  useLazyGetAllBookingsQuery,
  useRemoveAllBookingsMutation,
  useRemoveMeetingRoomBookingMutation,
} from '../../../api/rootApi';
import { TAB_VALUES } from '../../../constants';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { MyBooking, setAllBookings } from '../../../store/reducers/allBookings';
import { RemoveModal } from './removeModal';
import { RemoveRepeatBookingModal, REMOVE_RADIO_VALUES } from './removeRepeatBookingModal';

import styles from './styles';

const OFFSET = 0;
export const MIN_LIMIT = 7;

export interface SelectedItem {
  id: string;
  date: string;
}

function MyBookingsLayout() {
  const [tabValue, setTabValue] = useState<TAB_VALUES>(TAB_VALUES.ALL);
  const [expandedMyBookings, setExpandedMyBookings] = useState(true);
  const [isCheckedMyBookings, setIsCheckedMyBookings] = useState(false);
  const [expandedHistoryBookings, setExpandedHistoryBookings] = useState(true);
  const [isCheckedHistoryBookings, setIsCheckedHistoryBookings] = useState(false);
  const [accordionItems, setAccordionItems] = useState<MyBooking[]>([]);
  const [expiredAccordionItems, setExpiredAccordionItems] = useState<MyBooking[]>([]);
  const [selectedItems, setSelectedItems] = useState<SelectedItem[]>([]);
  const [expiredSelectedItems, setExpiredSelectedItems] = useState<SelectedItem[]>([]);
  const [removeModalActive, setRemoveModalActive] = useState(false);
  const [removeModeActive, setRemoveModeActive] = useState(false);
  const [expiredRemoveModeActive, setExpiredRemoveModeActive] = useState(false);
  const [removeRepeatBookingActive, setRemoveRepeatBookingActive] = useState(false);
  const [removeRepeatBooking, setRemoveRepeatBooking] = useState<MyBooking | null>(null);
  const [limit, setLimit] = useState(MIN_LIMIT);
  const [historyLimit, setHistoryLimit] = useState(MIN_LIMIT);
  const [showLoadMoreBtn, setShowLoadMoreBtn] = useState(false);
  const [showHistoryLoadMoreBtn, setShowHistoryLoadMoreBtn] = useState(false);
  const [selectedItemsCount, setSelectedItemsCount] = useState(0);
  const [removeRadioValue, setRemoveRadioValue] = useState<REMOVE_RADIO_VALUES>(
    REMOVE_RADIO_VALUES.CURRENT,
  );

  const { colorScheme, tgUserId } = useTelegram();

  const [getAllBookings, { isLoading, data }] = useLazyGetAllBookingsQuery();

  const [removeMeetingRoomBooking] = useRemoveMeetingRoomBookingMutation();

  const [removeAllBookings, { isLoading: isRemoveBookingsLoading }] =
    useRemoveAllBookingsMutation();

  const dispatch = useDispatch();

  useEffect(() => {
    if (tgUserId) {
      getAllBookings({
        telegramUserId: tgUserId,
        limit,
        offset: OFFSET,
        historyOffset: OFFSET,
        historyLimit,
      });
      if (isCheckedMyBookings) {
        setIsCheckedMyBookings(true);
      }
    }
  }, [limit, historyLimit]);

  const allBookings = useAppSelector((store) => store.allBookingsReducer.allBookings);

  const expiredAllBookings = useAppSelector((store) => store.allBookingsReducer.expiredAllBookings);

  const meetingRoomBookings = useAppSelector(
    (store) => store.allBookingsReducer.meetingRoomBookings,
  );

  const expiredMeetingRoomBookings = useAppSelector(
    (store) => store.allBookingsReducer.expiredMeetingRoomBookings,
  );

  const officeSpaceBookings = useAppSelector(
    (store) => store.allBookingsReducer.officeSpaceBookings,
  );

  const expiredOfficeSpaceBookings = useAppSelector(
    (store) => store.allBookingsReducer.expiredOfficeSpaceBookings,
  );

  useEffect(() => {
    if (data) {
      dispatch(
        setAllBookings({
          allBookings: data.allBookings,
          expiredAllBookings: data.expiredAllBookings,
          meetingRoomBookings: data.meetingRoomBookings,
          expiredMeetingRoomBookings: data.expiredMeetingRoomBookings,
          officeSpaceBookings: data.officeSpaceBookings,
          expiredOfficeSpaceBookings: data.expiredOfficeSpaceBookings,
          allBookingsCount: data.allBookingsCount,
          officeSpaceBookingsCount: data.officeSpaceBookingsCount,
          meetingRoomBookingsCount: data.meetingRoomBookingsCount,
          expiredAllBookingsCount: data.expiredAllBookingsCount,
          expiredOfficeSpaceBookingsCount: data.expiredOfficeSpaceBookingsCount,
          expiredMeetingRoomBookingsCount: data.expiredMeetingRoomBookingsCount,
        }),
      );
    }
  }, [data, limit, historyLimit]);

  const allBookingsCount = useAppSelector((store) => store.allBookingsReducer.allBookingsCount);

  const expiredAllBookingsCount = useAppSelector(
    (store) => store.allBookingsReducer.expiredAllBookingsCount,
  );

  const meetingRoomBookingsCount = useAppSelector(
    (store) => store.allBookingsReducer.meetingRoomBookingsCount,
  );

  const expiredMeetingRoomBookingsCount = useAppSelector(
    (store) => store.allBookingsReducer.expiredMeetingRoomBookingsCount,
  );

  const officeSpaceBookingsCount = useAppSelector(
    (store) => store.allBookingsReducer.officeSpaceBookingsCount,
  );

  const expiredOfficeSpaceBookingsCount = useAppSelector(
    (store) => store.allBookingsReducer.expiredOfficeSpaceBookingsCount,
  );

  const loadMoreHandler = () => {
    setLimit((prev) => prev + MIN_LIMIT);
  };

  const historyLoadMoreHandler = () => {
    setHistoryLimit((prev) => prev + MIN_LIMIT);
  };

  useEffect(() => {
    if (tabValue === TAB_VALUES.ALL) {
      setAccordionItems(allBookings);
      setExpiredAccordionItems(expiredAllBookings);
      if (allBookingsCount !== allBookings.length) {
        setShowLoadMoreBtn(true);
      } else {
        setShowLoadMoreBtn(false);
      }
      if (expiredAllBookingsCount !== expiredAllBookings.length) {
        setShowHistoryLoadMoreBtn(true);
      } else {
        setShowHistoryLoadMoreBtn(false);
      }

      if (isCheckedMyBookings) {
        const newSelectedItems = allBookings.map((item) => {
          if (item.isMeetingRoom) {
            return {
              id: item.id,
              date: item.date!,
            };
          }
          return {
            id: item.id,
            date: item.startDate!,
          };
        });
        setSelectedItems(newSelectedItems);
      }
      if (isCheckedHistoryBookings) {
        const newSelectedItems = expiredAllBookings.map((item) => {
          if (item.isMeetingRoom) {
            return {
              id: item.id,
              date: item.date!,
            };
          }
          return {
            id: item.id,
            date: item.startDate!,
          };
        });
        setExpiredSelectedItems(newSelectedItems);
      }
    }

    if (tabValue === TAB_VALUES.MEETING_ROOM) {
      setAccordionItems(meetingRoomBookings);
      setExpiredAccordionItems(expiredMeetingRoomBookings);
      if (meetingRoomBookingsCount !== meetingRoomBookings.length) {
        setShowLoadMoreBtn(true);
      } else {
        setShowLoadMoreBtn(false);
      }
      if (expiredMeetingRoomBookingsCount !== expiredMeetingRoomBookings.length) {
        setShowHistoryLoadMoreBtn(true);
      } else {
        setShowHistoryLoadMoreBtn(false);
      }
      if (isCheckedMyBookings) {
        const newSelectedItems = meetingRoomBookings.map((item) => {
          return {
            id: item.id,
            date: item.date!,
          };
        });
        setSelectedItems(newSelectedItems);
      }
      if (isCheckedHistoryBookings) {
        const newSelectedItems = expiredMeetingRoomBookings.map((item) => {
          return {
            id: item.id,
            date: item.date!,
          };
        });
        setExpiredSelectedItems(newSelectedItems);
      }
    }

    if (tabValue === TAB_VALUES.DESK) {
      setAccordionItems(officeSpaceBookings);
      setExpiredAccordionItems(expiredOfficeSpaceBookings);
      if (officeSpaceBookingsCount !== officeSpaceBookings.length) {
        setShowLoadMoreBtn(true);
      } else {
        setShowLoadMoreBtn(false);
      }
      if (expiredOfficeSpaceBookingsCount !== expiredOfficeSpaceBookings.length) {
        setShowHistoryLoadMoreBtn(true);
      } else {
        setShowHistoryLoadMoreBtn(false);
      }
      if (isCheckedMyBookings) {
        const newSelectedItems = officeSpaceBookings.map((item) => {
          return {
            id: item.id,
            date: item.startDate!,
          };
        });
        setSelectedItems(newSelectedItems);
      }
      if (isCheckedHistoryBookings) {
        const newSelectedItems = expiredOfficeSpaceBookings.map((item) => {
          return {
            id: item.id,
            date: item.startDate!,
          };
        });
        setExpiredSelectedItems(newSelectedItems);
      }
    }
  }, [tabValue, allBookings, expiredAllBookings]);

  const removeBtnHandler = () => {
    setRemoveModalActive(true);
  };

  const onDeleteBookingsHandler = async () => {
    let result;

    if (selectedItems.length !== 0 || expiredSelectedItems.length !== 0) {
      const bookingIds = [...selectedItems, ...expiredSelectedItems].map((i) => i.id);

      const removeBody = {
        removeAll: isCheckedHistoryBookings || isCheckedMyBookings,
        isHistory: expiredSelectedItems.length !== 0,
        isMeetingRoom: tabValue === TAB_VALUES.ALL || tabValue === TAB_VALUES.MEETING_ROOM,
        isOfficeSpace: tabValue === TAB_VALUES.ALL || tabValue === TAB_VALUES.DESK,
        bookingIds,
      };

      result = await removeAllBookings(removeBody).unwrap();
    }

    if (result?.message) {
      setRemoveModalActive(false);
      setIsCheckedMyBookings(false);
      setIsCheckedHistoryBookings(false);
      setSelectedItems([]);
      setExpiredSelectedItems([]);
      setRemoveModeActive(false);
      setExpiredRemoveModeActive(false);
    }
  };

  const onRemoveRepeatBooking = async () => {
    if (removeRepeatBooking) {
      const result = await removeMeetingRoomBooking({
        id: removeRepeatBooking.id,
        removeDate: removeRepeatBooking.date,
        removeUpcoming: removeRadioValue === REMOVE_RADIO_VALUES.UPCOMING,
        removeAll: removeRadioValue === REMOVE_RADIO_VALUES.ALL,
        removeCurrent: removeRadioValue === REMOVE_RADIO_VALUES.CURRENT,
      }).unwrap();
      if (result) {
        setRemoveRepeatBookingActive(false);
      }
    }
  };

  useEffect(() => {
    setSelectedItemsCount(selectedItems.length + expiredSelectedItems.length);

    if (tabValue === TAB_VALUES.ALL) {
      if (allBookingsCount !== allBookings.length && isCheckedMyBookings) {
        if (expiredSelectedItems.length > 0) {
          if (isCheckedHistoryBookings) {
            setSelectedItemsCount(expiredAllBookingsCount + allBookingsCount);
          } else {
            setSelectedItemsCount(expiredSelectedItems.length + allBookingsCount);
          }
        } else {
          setSelectedItemsCount(allBookingsCount);
        }
      }
      if (expiredAllBookingsCount !== expiredAllBookings.length && isCheckedHistoryBookings) {
        if (selectedItems.length > 0) {
          if (isCheckedMyBookings) {
            setSelectedItemsCount(expiredAllBookingsCount + allBookingsCount);
          } else {
            setSelectedItemsCount(selectedItems.length + expiredAllBookingsCount);
          }
        } else {
          setSelectedItemsCount(expiredAllBookingsCount);
        }
      }
    }

    if (tabValue === TAB_VALUES.MEETING_ROOM) {
      if (meetingRoomBookingsCount !== meetingRoomBookings.length && isCheckedMyBookings) {
        if (expiredSelectedItems.length > 0) {
          if (isCheckedHistoryBookings) {
            setSelectedItemsCount(expiredMeetingRoomBookingsCount + meetingRoomBookingsCount);
          } else {
            setSelectedItemsCount(expiredSelectedItems.length + meetingRoomBookingsCount);
          }
        } else {
          setSelectedItemsCount(meetingRoomBookingsCount);
        }
      }
      if (
        expiredMeetingRoomBookingsCount !== expiredMeetingRoomBookings.length &&
        isCheckedHistoryBookings
      ) {
        if (selectedItems.length > 0) {
          if (isCheckedMyBookings) {
            setSelectedItemsCount(expiredMeetingRoomBookingsCount + meetingRoomBookingsCount);
          } else {
            setSelectedItemsCount(selectedItems.length + expiredMeetingRoomBookingsCount);
          }
        } else {
          setSelectedItemsCount(expiredMeetingRoomBookingsCount);
        }
      }
    }

    if (tabValue === TAB_VALUES.DESK) {
      if (officeSpaceBookingsCount !== officeSpaceBookings.length && isCheckedMyBookings) {
        if (expiredSelectedItems.length > 0) {
          if (isCheckedHistoryBookings) {
            setSelectedItemsCount(expiredOfficeSpaceBookingsCount + officeSpaceBookingsCount);
          } else {
            setSelectedItemsCount(expiredSelectedItems.length + officeSpaceBookingsCount);
          }
        } else {
          setSelectedItemsCount(officeSpaceBookingsCount);
        }
      }
      if (
        expiredOfficeSpaceBookingsCount !== expiredOfficeSpaceBookings.length &&
        isCheckedHistoryBookings
      ) {
        if (selectedItems.length > 0) {
          if (isCheckedMyBookings) {
            setSelectedItemsCount(expiredOfficeSpaceBookingsCount + officeSpaceBookingsCount);
          } else {
            setSelectedItemsCount(selectedItems.length + expiredOfficeSpaceBookingsCount);
          }
        } else {
          setSelectedItemsCount(expiredOfficeSpaceBookingsCount);
        }
      }
    }
  }, [selectedItems, expiredSelectedItems]);

  return (
    <div css={styles.wrapperStyles({ colorScheme })}>
      {isLoading || isRemoveBookingsLoading ? (
        <Grid item padding={7} display="flex" justifyContent="center" xs={12}>
          <CircularProgress />
        </Grid>
      ) : (
        <>
          <MyBookingsTabs
            tabValue={tabValue}
            setTabValue={setTabValue}
            setLimit={setLimit}
            setIsCheckedMyBookings={setIsCheckedMyBookings}
            setIsCheckedHistoryBookings={setIsCheckedHistoryBookings}
            setSelectedItems={setSelectedItems}
            setExpiredSelectedItems={setExpiredSelectedItems}
            setExpandedMyBookings={setExpandedMyBookings}
            setExpandedHistoryBookings={setExpandedHistoryBookings}
            setRemoveModeActive={setRemoveModeActive}
            setExpiredRemoveModeActive={setExpiredRemoveModeActive}
          />
          <BookingsAccordion
            accordionItems={accordionItems}
            expanded={expandedMyBookings}
            setExpanded={setExpandedMyBookings}
            isChecked={isCheckedMyBookings}
            setIsChecked={setIsCheckedMyBookings}
            accordionTitle="My Bookings"
            setSelectedItems={setSelectedItems}
            selectedItems={selectedItems}
            removeModeActive={removeModeActive}
            setRemoveModeActive={setRemoveModeActive}
            otherRemoveActive={expiredRemoveModeActive}
            setRemoveRepeatBookingActive={setRemoveRepeatBookingActive}
            setRemoveRepeatBooking={setRemoveRepeatBooking}
            showLoadMoreBtn={showLoadMoreBtn}
            loadMoreHandler={loadMoreHandler}
          />
          <BookingsAccordion
            accordionItems={expiredAccordionItems}
            expanded={expandedHistoryBookings}
            setExpanded={setExpandedHistoryBookings}
            isChecked={isCheckedHistoryBookings}
            setIsChecked={setIsCheckedHistoryBookings}
            accordionTitle="History"
            setSelectedItems={setExpiredSelectedItems}
            selectedItems={expiredSelectedItems}
            removeModeActive={expiredRemoveModeActive}
            setRemoveModeActive={setExpiredRemoveModeActive}
            otherRemoveActive={removeModeActive}
            setRemoveRepeatBookingActive={setRemoveRepeatBookingActive}
            setRemoveRepeatBooking={setRemoveRepeatBooking}
            showLoadMoreBtn={showHistoryLoadMoreBtn}
            loadMoreHandler={historyLoadMoreHandler}
          />
          <RemoveModal
            open={removeModalActive}
            setOpen={setRemoveModalActive}
            itemsCount={selectedItemsCount}
            onDeleteBookingsHandler={onDeleteBookingsHandler}
          />
          <RemoveRepeatBookingModal
            open={removeRepeatBookingActive}
            onClose={() => setRemoveRepeatBookingActive(false)}
            onDeleteWorkPlaceHandler={onRemoveRepeatBooking}
            removeRadioValue={removeRadioValue}
            setRemoveRadioValue={setRemoveRadioValue}
          />
        </>
      )}
      {(!!selectedItems.length || !!expiredSelectedItems.length) &&
        (removeModeActive || expiredRemoveModeActive) && (
          <AppBar position="fixed" css={styles.appBarStyles}>
            <button type="button" css={styles.deleteBtnStyles} onClick={removeBtnHandler}>
              Delete
            </button>
          </AppBar>
        )}
    </div>
  );
}

export default MyBookingsLayout;
