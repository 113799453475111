/* eslint-disable jsx-a11y/label-has-associated-control */
/** @jsxImportSource @emotion/react */
import React, { ChangeEvent } from 'react';
import { SerializedStyles } from '@emotion/react';
import dayjs from 'dayjs';
import CheckboxIcon from '../../../assets/checkboxIcon';
import { useTelegram } from '../../../hooks/useTelegram';

import styles from './styles';

const isBetween = require('dayjs/plugin/isBetween');

dayjs.extend(isBetween);

interface BookingsAccordionProps {
  isChecked: boolean;
  onChangeHandler: (e: ChangeEvent<HTMLInputElement>) => void;
  showLabel?: boolean;
  wrapperStyles?: SerializedStyles;
  label?: string;
  checkboxLabelStyles?: SerializedStyles;
  disabled?: boolean;
}

function Checkbox({
  isChecked,
  onChangeHandler,
  showLabel,
  wrapperStyles,
  label,
  checkboxLabelStyles,
  disabled,
}: BookingsAccordionProps) {
  const { colorScheme } = useTelegram();
  return (
    <div css={wrapperStyles || styles.checkboxWrapperStyles({ colorScheme })}>
      <label css={styles.checkboxLabelStyles({ colorScheme })}>
        <input
          disabled={disabled}
          css={styles.checkboxStyles({ colorScheme, isChecked, disabled })}
          type="checkbox"
          checked={isChecked}
          onChange={onChangeHandler}
        />
        {isChecked && <CheckboxIcon iconStyles={styles.iconStyles({ showLabel })} />}
        {showLabel && (
          <span css={checkboxLabelStyles || styles.checkboxTitleStyles({ colorScheme })}>
            {label}
          </span>
        )}
      </label>
    </div>
  );
}

export default Checkbox;
