/** @jsxImportSource @emotion/react */
import dayjs, { Dayjs } from 'dayjs';
import { MenuItem, Radio, TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React, { ChangeEvent, useState } from 'react';
import { useStyles } from '../../../../hooks/useStyles';
import { useTelegram } from '../../../../hooks/useTelegram';
import Checkbox from '../../../common/checkbox';
import { REPEAT_VALUE } from '../../../../constants';
import { useAppSelector } from '../../../../hooks/useAppSelector';

import styles from './styles';

const REPEAT_VALUES = [
  { value: 'day', label: 'Day' },
  { value: 'week', label: 'Week' },
  { value: 'month', label: 'Month' },
];

interface RepeatButtonsProps {
  checkedRepeatMode: boolean;
  setCheckedRepeatMode: React.Dispatch<React.SetStateAction<boolean>>;
  repeatEndDate: Dayjs | null;
  setRepeatEndDate: React.Dispatch<React.SetStateAction<Dayjs | null>>;
  repeatRadioValue: string;
  setRepeatRadioValue: React.Dispatch<React.SetStateAction<string>>;
  repeatValue: REPEAT_VALUE;
  setRepeatValue: React.Dispatch<React.SetStateAction<REPEAT_VALUE>>;
  editModeActive: boolean;
  chosenDateState?: string;
}

function RepeatButtons({
  checkedRepeatMode,
  setCheckedRepeatMode,
  repeatEndDate,
  setRepeatEndDate,
  repeatRadioValue,
  setRepeatRadioValue,
  repeatValue,
  setRepeatValue,
  editModeActive,
  chosenDateState,
}: RepeatButtonsProps) {
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      // @ts-ignore
      setRepeatValue(e.target.value);
    }
  };

  const { colorScheme } = useTelegram();

  const classes = useStyles({ colorScheme });

  const checkboxHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setCheckedRepeatMode(e.currentTarget.checked);
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRepeatRadioValue(event.target.value);
  };

  const editedMeetingRoomBooking = useAppSelector(
    (store) => store.meetingRoomsReducer.editedUserMeetingRoom,
  );

  const isDisabled = !editModeActive && !!editedMeetingRoomBooking;

  let repeatBookingDescription;

  const repeatedStartDate = dayjs(editedMeetingRoomBooking?.userMeetingRoomInfo.date);

  const repeatedEndDate = dayjs(editedMeetingRoomBooking?.userMeetingRoomInfo.repeatEndDate);

  const formattedEndDate = repeatedEndDate.format('DD MMM');

  if (editedMeetingRoomBooking?.userMeetingRoomInfo.repeatValue === REPEAT_VALUE.DAY) {
    if (editedMeetingRoomBooking?.userMeetingRoomInfo.isRepeatAlways === false) {
      repeatBookingDescription = `Daily until ${formattedEndDate}`;
    } else {
      repeatBookingDescription = `Daily`;
    }
  }

  if (editedMeetingRoomBooking?.userMeetingRoomInfo.repeatValue === REPEAT_VALUE.WEEK) {
    if (editedMeetingRoomBooking?.userMeetingRoomInfo.isRepeatAlways === false) {
      repeatBookingDescription = `Weekly on ${repeatedStartDate.format(
        'dddd',
      )}, until ${formattedEndDate}`;
    } else {
      repeatBookingDescription = `Weekly on ${repeatedStartDate.format('dddd')}`;
    }
  }

  if (editedMeetingRoomBooking?.userMeetingRoomInfo.repeatValue === REPEAT_VALUE.MONTH) {
    if (editedMeetingRoomBooking?.userMeetingRoomInfo.isRepeatAlways === false) {
      repeatBookingDescription = `Monthly on ${repeatedStartDate.format(
        'DD',
      )}, until ${formattedEndDate}`;
    } else {
      repeatBookingDescription = `Monthly on ${repeatedStartDate.format('DD')}`;
    }
  }

  return (
    <div css={styles.wrapperStyles({ colorScheme })}>
      <div css={styles.checkboxWrapperStyles}>
        <Checkbox
          disabled={isDisabled}
          isChecked={checkedRepeatMode}
          onChangeHandler={checkboxHandler}
          wrapperStyles={styles.checkboxWrapperStyles}
          checkboxLabelStyles={styles.checkboxLabelStyles({ colorScheme })}
          showLabel
          label="Repeat"
        />
        {!!editedMeetingRoomBooking && checkedRepeatMode && (
          <p css={styles.selectTitleStyles({ colorScheme, disabled: isDisabled })}>
            {repeatBookingDescription}
          </p>
        )}
      </div>
      {checkedRepeatMode && (
        <>
          <div css={styles.selectWrapperStyles({ colorScheme })}>
            <p css={styles.repeatLabelStyles({ colorScheme })}>Repeat every</p>
            <TextField
              disabled={isDisabled}
              className={classes.root}
              select
              value={repeatValue}
              variant="outlined"
              onChange={handleChange}
              SelectProps={{
                MenuProps: {
                  PopoverClasses: classes,
                  autoFocus: false,
                  PaperProps: {
                    className: 'repeatMenu',
                  },
                },
              }}
              InputLabelProps={{
                className: 'inputLabel',
                shrink: true,
              }}>
              {REPEAT_VALUES.map((v) => {
                return (
                  <MenuItem
                    value={v.value}
                    key={v.value}
                    css={styles.menuItemStyles({ colorScheme })}>
                    {v.label}
                  </MenuItem>
                );
              })}
            </TextField>
          </div>
          <div css={styles.selectWrapperStyles({ colorScheme })}>
            <p css={styles.repeatLabelStyles({ colorScheme })}>Ends</p>
            <div css={styles.radioButtonsWrapperStyles}>
              <div css={styles.radioButtonWrapperStyles}>
                <Radio
                  disabled={isDisabled}
                  checked={repeatRadioValue === 'Never'}
                  onChange={handleRadioChange}
                  value="Never"
                />
                <span css={styles.radioBtnTitleStyles({ colorScheme })}>Never</span>
              </div>
              <div css={styles.radioButtonWrapperStyles}>
                <Radio
                  disabled={isDisabled}
                  checked={repeatRadioValue === 'On'}
                  onChange={handleRadioChange}
                  value="On"
                />
                <span css={styles.radioBtnTitleStyles({ colorScheme })}>On</span>
              </div>
            </div>
            {repeatRadioValue === 'On' && (
              <div css={styles.datePickerWrapperStyles}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disabled={isDisabled}
                    className={classes.root}
                    open={datePickerOpen}
                    onOpen={() => !isDisabled && setDatePickerOpen(true)}
                    onClose={() => !isDisabled && setDatePickerOpen(false)}
                    minDate={dayjs(chosenDateState)}
                    maxDate={dayjs(chosenDateState).add(1, 'year')}
                    showToolbar={false}
                    inputFormat="DD.MM.YYYY"
                    value={repeatEndDate}
                    PaperProps={{
                      className: classes.root,
                      sx: {
                        '& .PrivatePickersSlideTransition-root': {
                          minHeight: '230px',
                        },
                      },
                    }}
                    InputProps={{
                      sx: {
                        '& .MuiOutlinedInput-input': {
                          width: '100px',
                          fontSize: '16px !important',
                        },
                        '& .MuiInputAdornment-root': {
                          marginLeft: '0px',
                        },
                        width: 'auto !important',
                      },
                    }}
                    onChange={(newValue: React.SetStateAction<dayjs.Dayjs | null>) => {
                      setRepeatEndDate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        inputProps={{ sx: { width: '100px' } }}
                        InputLabelProps={{ className: 'inputLabel' }}
                        {...params}
                        disabled={!editModeActive && !!editedMeetingRoomBooking}
                        onClick={(e) => {
                          if (!isDisabled) {
                            setDatePickerOpen(true);
                          }
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default RepeatButtons;
