/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/** @jsxImportSource @emotion/react */
import React from 'react';
import dayjs from 'dayjs';
import { useEnhancedNavigate } from '../../../../hooks/useEnhancedNavigate';
import { APP_ROUTES, MAX_TIME, MEETING_ROOMS, MIN_TIME } from '../../../../constants';
import { useTelegram } from '../../../../hooks/useTelegram';

import styles from './styles';

const duration = require('dayjs/plugin/duration');

dayjs.extend(duration);

export const MINUTES_IN_HOUR = 60;

interface TimeRowProps {
  meetingRoomDateValue?: string;
  isTimeRowClickable: boolean;
  allMeetingRooms?: boolean;
  meetingRoomName?: string;
  enableStartTime: string;
  enableEndTime: string;
}

function EmptyRow({
  meetingRoomDateValue,
  isTimeRowClickable,
  allMeetingRooms,
  meetingRoomName,
  enableStartTime,
  enableEndTime,
}: TimeRowProps) {
  const { colorScheme } = useTelegram();

  const { scrollNavigate } = useEnhancedNavigate();

  const nowDate = dayjs().format('YYYY-MM-DD');

  const enableStartTimeDayjs = dayjs(`${nowDate} ${enableStartTime}`);

  const enableEndTimeDayjs = dayjs(`${nowDate} ${enableEndTime}`);

  // @ts-ignore
  const timeDuration = dayjs.duration(enableEndTimeDayjs.diff(enableStartTimeDayjs));

  // Calculating Top
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const top = `calc((100% / 16 * ${enableStartTimeDayjs.get('hour') - 7}) ${
    enableStartTimeDayjs.get('minutes')
      ? `+ (100% / 16 / 60 * ${enableStartTimeDayjs.get('minutes')})`
      : ''
  })`;

  // Calculating height
  const timeFactor = timeDuration.hours() + timeDuration.minutes() / MINUTES_IN_HOUR;

  const height = `calc(100% / 16 * ${timeFactor})`;

  const onTimeCellClickHandler = ({
    isAllMeetingRooms,
    startTimeValue,
    endTimeValue,
  }: {
    isAllMeetingRooms?: boolean;
    startTimeValue?: string;
    endTimeValue?: string;
  }) => {
    if (meetingRoomDateValue) {
      const startTime = dayjs(`${nowDate} ${startTimeValue}`)
        .set('milliseconds', 0)
        .format('HH:mm:ss');

      const endTime = dayjs(`${nowDate} ${endTimeValue}`).set('milliseconds', 0).format('HH:mm:ss');

      if (isAllMeetingRooms && meetingRoomName) {
        const meetingRoomNumber = meetingRoomName.split('')[2];

        const meetingRoomId = MEETING_ROOMS.find(
          (m) => m.name === `Meeting Room ${meetingRoomNumber}`,
        )?.id;

        scrollNavigate({
          top: 0,
          left: 0,
          path: `${
            APP_ROUTES.BOOK_MEETING_ROOM
          }?bookingStartTime=${startTime}&bookingEndTime=${endTime}&meetingRoomId=${meetingRoomId}&bookingDate=${meetingRoomDateValue}&meetingRoomName=Meeting room ${meetingRoomNumber}&isAllMeetingRooms=${true}`,
          replace: true,
          behavior: 'smooth',
        });
      } else if (meetingRoomName) {
        const meetingRoomId = MEETING_ROOMS.find((m) => m.name === meetingRoomName)?.id;

        scrollNavigate({
          top: 0,
          left: 0,
          path: `${APP_ROUTES.BOOK_MEETING_ROOM}?bookingStartTime=${startTime}&bookingEndTime=${endTime}&meetingRoomId=${meetingRoomId}&bookingDate=${meetingRoomDateValue}&meetingRoomName=${meetingRoomName}`,
          replace: true,
          behavior: 'smooth',
        });
      }
    }
  };

  return (
    <div
      onClick={
        isTimeRowClickable
          ? () =>
              onTimeCellClickHandler({
                isAllMeetingRooms: allMeetingRooms,
                startTimeValue: enableStartTime,
                endTimeValue: enableEndTime,
              })
          : () => {}
      }
      css={styles.enableTimeRow({
        first:
          enableStartTimeDayjs.get('minutes') === 0 &&
          enableStartTimeDayjs.get('hour') === MIN_TIME,
        last:
          enableEndTimeDayjs.get('minutes') === MINUTES_IN_HOUR &&
          MAX_TIME === enableEndTimeDayjs.get('hour'),
        height,
        top,
        colorScheme,
        allMeetingRooms,
        meetingRoomName,
      })}
    />
  );
}

export default EmptyRow;
