/* eslint-disable @typescript-eslint/no-magic-numbers */
import { useEffect } from 'react';
/* import { useDispatch } from 'react-redux'; */
/* import { CircularProgress, Grid } from '@mui/material'; */
import { useTelegram } from './hooks/useTelegram';
import { Router } from './routes';
import { /* useLazyGetGoogleAuthQuery, */ useLazyGetUserQuery } from './api/rootApi';
/* import { useAppSelector } from './hooks/useAppSelector'; */
/* import Header from './components/layouts/header'; */
/* import { setUserAuth } from './store/reducers/user'; */

import './index.css';

function App() {
  const { tg, tgUserId } = useTelegram();

  const [getUser] = useLazyGetUserQuery();

  /*   const [getUser] = useLazyGetUserQuery();

  const [getGoogleAuth, { isLoading }] = useLazyGetGoogleAuthQuery(); */

  /*   const isUserAuth = useAppSelector((state) => state.userReducer.user.isAuth); */

  /*   const dispatch = useDispatch(); */

  useEffect(() => {
    tg.ready();
    tg.expand();
    if (tgUserId) {
      getUser({ telegramUserId: tgUserId });
    }
  }, []);

  /* useEffect(() => {
    if (isUserAuth) {
      window.close();
      if (tgUserId) {
        getUser({ telegramUserId: tgUserId });
      }
    } else {
      const sendAuth = async () => {
        if (tgUserId) {
          const response: any = await getGoogleAuth({ telegramUserId: tgUserId }).unwrap();

          if (response) {
            dispatch(
              setUserAuth({
                isAuth: true,
                email: response.email,
              }),
            );
          }
        }
      };

      sendAuth();
    }
  }, [isUserAuth]); */

  /*   if (isLoading && isUserAuth) {
    return (
      <Grid item padding={7} display="flex" justifyContent="center" xs={12}>
        <CircularProgress />
      </Grid>
    );
  } */

  return (
    <div className="mainLayout">
      {/*    <Header /> */}
      <Router />
    </div>
  );
}

export default App;
