/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const wrapperStyles = css({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100vh',
  padding: '40px',
});

const headerTextStyles = css({
  color: 'black',
  fontSize: '20px',
  fontWeight: 600,
  lineHeight: '24px',
  marginTop: '20px',
});

const styles = {
  wrapperStyles,
  headerTextStyles,
};

export default styles;
