/* eslint-disable @typescript-eslint/no-magic-numbers */
import dayjs, { Dayjs } from 'dayjs';
import { MeetingRoomUser } from '../store/reducers/meetingRooms';

const isBetween = require('dayjs/plugin/isBetween');

dayjs.extend(isBetween);

const MIN_MINUTES_VALUE = 15;

export const isTimeDisabled = (
  time: string,
  meetingRoomUsers: MeetingRoomUser[],
  isEndTime?: boolean,
  startTimeValue?: Dayjs | null,
) => {
  const nowDate = dayjs().format('YYYY-MM-DD');

  const maxTime = dayjs(`${nowDate} 23:00:00`);

  const minTime = dayjs(`${nowDate} 07:00:00`);

  const chosenTime = dayjs(`${nowDate} ${time}:00`);

  if (!isEndTime && (time === '22:50' || time === '22:55' || time === '23:00')) {
    return true;
  }

  if (chosenTime > maxTime || chosenTime < minTime) {
    return true;
  }

  if (
    meetingRoomUsers.length === 0 &&
    isEndTime &&
    startTimeValue &&
    chosenTime < startTimeValue.add(MIN_MINUTES_VALUE, 'minutes')
  ) {
    return true;
  }

  const timeHour = Number(time.split(':')[0]);

  const timeDiffInOneBookedHour = meetingRoomUsers.reduce((acc, current) => {
    const startTime = dayjs(
      `${current.userMeetingRoomInfo.date} ${current.userMeetingRoomInfo.startTime}`,
    );

    const endTime = dayjs(
      `${current.userMeetingRoomInfo.date} ${current.userMeetingRoomInfo.endTime}`,
    );

    if (
      startTime.get('hour') === timeHour &&
      (endTime.get('hour') === timeHour ||
        (endTime.get('hour') === timeHour + 1 && endTime.get('minutes') === 0))
    ) {
      acc += endTime.diff(startTime, 'minutes');
    }

    return acc;
  }, 0);

  const disabled = meetingRoomUsers.some((u, index, arr) => {
    let nextBookedStartTime;

    const bookedStartTime = dayjs(`${nowDate} ${u.userMeetingRoomInfo.startTime}`);

    const bookedEndTime = dayjs(`${nowDate} ${u.userMeetingRoomInfo.endTime}`);

    if (index !== arr.length - 1 && chosenTime < bookedStartTime) {
      nextBookedStartTime = dayjs(`${nowDate} ${arr[index + 1].userMeetingRoomInfo.startTime}`);
    }

    // @ts-ignore
    const isStartTimeDisable = chosenTime.isBetween(
      bookedStartTime.subtract(MIN_MINUTES_VALUE, 'minutes'),
      bookedEndTime,
      'minutes',
      '()',
    );

    // @ts-ignore
    const isEndTimeDisable = chosenTime.isBetween(
      bookedStartTime,
      bookedEndTime.add(MIN_MINUTES_VALUE, 'minutes'),
      'minutes',
      '()',
    );

    // @ts-ignore
    const isStartTimeBetween = startTimeValue?.isBetween(
      bookedStartTime,
      bookedEndTime.add(MIN_MINUTES_VALUE, 'minutes'),
      'minutes',
      '[]',
    );

    if (isStartTimeDisable && !isEndTime) {
      return true;
    }

    if (
      (isEndTimeDisable && isEndTime) ||
      (isEndTime &&
        startTimeValue &&
        startTimeValue.diff(chosenTime, 'minutes') + MIN_MINUTES_VALUE > 0) ||
      (isEndTime && nextBookedStartTime && chosenTime > nextBookedStartTime) ||
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      (isEndTime && timeDiffInOneBookedHour > 60) ||
      (isEndTime &&
        startTimeValue &&
        !isStartTimeBetween &&
        chosenTime > bookedEndTime &&
        bookedStartTime >= startTimeValue)
    ) {
      return true;
    }

    return false;
  });

  return disabled;
};
