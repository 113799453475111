/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { validationSchema } from '../../../utils/validators/registrationForm';
import { useTelegram } from '../../../hooks/useTelegram';
import { Input } from '../../common/input';
import { NAME_MAX_LENGTH, SURNAME_MAX_LENGTH } from '../../../constants';
import { useRegisterUserMutation } from '../../../api/rootApi';

import styles from './styles';

function RegistrationLayout() {
  const formOption = {
    defaultValues: {
      name: '',
      surname: '',
    },
    resolver: yupResolver(validationSchema),
  };

  const { handleSubmit, register, formState, getValues } = useForm(formOption);

  const { dirtyFields, errors } = formState;

  const { tg, user, tgUserId, queryId, colorScheme } = useTelegram();

  const [registerUser] = useRegisterUserMutation();

  const onSubmit: SubmitHandler<any> = async (formData): Promise<void> => {
    const { name, surname } = formData;

    if (user && tgUserId && queryId) {
      const data = {
        name,
        surname,
        telegramUserName: user,
        telegramUserId: tgUserId,
        queryId,
      };

      registerUser(data);
    }
  };

  useEffect(() => {
    tg.onEvent('mainButtonClicked', handleSubmit(onSubmit));
    return () => {
      tg.offEvent('mainButtonClicked', handleSubmit(onSubmit));
    };
  }, [onSubmit]);

  useEffect(() => {
    tg.MainButton.setParams({
      text: 'Send info',
    });
  }, []);

  const name = getValues('name');

  const surname = getValues('surname');

  useEffect(() => {
    if (!dirtyFields.name || !dirtyFields.surname) {
      tg.MainButton.hide();
    } else {
      tg.MainButton.show();
    }
  }, [surname, name]);

  return (
    <div css={styles.formStyles({ colorScheme })}>
      <h3 css={styles.headerTextStyles({ colorScheme })}>Put your info</h3>
      <form>
        <div>
          <Input
            maxLength={NAME_MAX_LENGTH}
            name="name"
            placeholder="Name"
            errorMsg={errors.name?.message}
            label="Name"
            variant="outlined"
            register={register}
            InputLabelProps={{ className: 'inputLabel' }}
          />
        </div>
        <div css={styles.inputWrapperStyles({ surnameInput: true })}>
          <Input
            maxLength={SURNAME_MAX_LENGTH}
            name="surname"
            placeholder="Last Name"
            variant="outlined"
            errorMsg={errors.surname?.message}
            label="Last Name"
            register={register}
            InputLabelProps={{
              className: 'inputLabel',
            }}
          />
        </div>
      </form>
    </div>
  );
}

export default RegistrationLayout;
